import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loginService } from "../../services/http.service";
import styles from "./forget.module.css";
import { changePassword } from "../../services/user.service";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mtdc.com.mv/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const SetPass = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, otp } = location?.state;
  console.log("lcoation", location?.state);
  const { mob } = useParams();
  const [data, setData] = React.useState({
    password: "",
    confirm: "",
  });
  // const
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (data?.password !== data?.confirm) {
      toast.error("Passwords don't match. Please try again");
    } else {
      const payload = {
        phoneNumber: mob,
        code: otp,
        email: email,
        newPassword: data?.password,
      };
      changePassword(payload).then((res)=>{
        if(res){
          toast.success(res?.message)
          navigate("/login")
        }
      })
      console.log("payload", payload);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <Grid container component="main" sx={{ height: "100vh" }}> */}
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={styles.main}
        sx={{
          backgroundImage: "url(/images/signup_bgimage.jpeg)",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          backgroundPosition: "center",
          gap: "1rem",
        }}
      >
        <div
          className={styles.logo}
          style={{
            width: "100%",
            height: "auto",
            margin: "0.5rem auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/mtdc_logo.png"
            alt=""
            // style={{ width: "35%", height: "auto", }}
          />
        </div>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            margin: "0 auto",
            height: "52%",
          }}
          className={styles.registerBx}
          elevation={6}
          square
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ m: "1rem 0" }}
            className={styles.boxForm}
          >
            {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className={!checked ? styles.active : styles.nonactive} >Share Holder</p>
                <Switch checked={checked} onChange={handleChange} />
                <p className={checked ? styles.active : styles.nonactive}>Proxy</p>
              </div> */}
            <Typography
              style={{
                padding: "1.5rem 0",
                color: "#fff",
                fontSize: "23px",
                textAlign: "center",
                fontWeight: "400",
                lineHeight: "normal",
                textDecorationLine: "underline",
              }}
            >
              Create New Password
            </Typography>

            <TextField
              className={styles.inputField}
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="id"
              label="New password"
              // placeholder="National ID*"
              name="password"
              autoComplete="id"
              autoFocus
              onChange={handleChange}
            />
            <TextField
              className={styles.inputField}
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="id"
              label="Confirm password"
              // placeholder="National ID*"
              name="confirm"
              autoComplete="id"
              autoFocus
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" sx={{ color: "white" }} />}
              label="Remember me"
              sx={{
                color: "white",
                fontSize: "14px",
                float: "left",
                marginTop: "1rem",
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                marginTop: "1rem",
                marginBottom: "1.2rem",
                bgcolor: "#22C25E",
                fontSize: "21px",
                fontWeight: "bold",
                borderRadius: "9px",
              }}
            >
              Create
            </Button>
            <Grid container sx={{ display: "flex", justifyContent: "end" }}>
              {/* <Grid item xs>
                  <Link href="#" variant="body2" style={{color:"white"}}>
                    Forgot password?
                  </Link>
                </Grid> */}
              <Link
                variant="body2"
                sx={{ color: "white" }}
                onClick={() => navigate("/signup")}
                style={{
                  color: "white",
                  fontSize: "17px",
                  fontWeight: "300",
                  cursor: "pointer",
                }}
              >
                {"Don't have account?Register here"}
              </Link>
            </Grid>
            {/* <Copyright sx={{ mt: 5, color:"white" }} /> */}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
