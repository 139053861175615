import React, { useEffect, useState } from "react";
import "./personaldetails.css";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { Atolls, getIslandBasedOnAtoll } from "../../services/data.service";

const AddressDetails = () => {
  const navigate = useNavigate();
  const [same, setSame] = useState(false);
  const location = useLocation();
  const [atolls, setAtolls] = useState();
  const [Islands, setIslands] = useState({
    permanent: [],
    current: [],
  });

  const [permanentAddress, setPermanentAddress] = useState({
    Atoll: "",
    Island: "",
    HouseNo: "",
    Street: "",
    Zip: "",
    City: "",
    Country: "",
  });

  const [currentAddress, setCurrentAddress] = useState({
    Atoll: "",
    Island: "",
    HouseNo: "",
    Street: "",
    Zip: "",
    City: "",
    Country: "",
  });

  const handleChangePermanent = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPermanentAddress({
      ...permanentAddress,
      [name]: value,
    });
  };

  const handleChangeCurrent = (e) => {
    e.preventDefault();
    if (!same) {
      const { name, value } = e.target;
      setCurrentAddress({
        ...currentAddress,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (location?.state?.Current_address) {
      setCurrentAddress(location?.state?.Current_address);
    }
    if (location?.state?.Permanent_address) {
      setPermanentAddress(location?.state?.Permanent_address);
    }
  }, [location?.state]);

  useEffect(() => {
    if (same == true) {
      setCurrentAddress(permanentAddress);
    }
  }, [same]);

  useEffect(() => {
    Atolls().then((res) => {
      if (res?.result) {
        setAtolls(res?.result);
      }
    });
  }, []);

  const handleSubmit = () => {
    // console.log("check", { permanentAddress, currentAddress });
    navigate("/signup-docs", {
      state: {
        ...location?.state,
        Permanent_address: permanentAddress,
        Current_address: currentAddress,
      },
    });
  };

  // console.log("permanent Addres", permanentAddress);
  // console.log("Data", location?.state);

  return (
    <div>
      <div className="main">
        <div className="logo-box mb-4 mt-0 w-[100%] h-auto flex justify-center items-center bg-[#CEDEFE]">
          <img
            src="/images/mtdc_logo.png"
            alt="image"
            style={{ height: "auto", width: "25%" }}
          />
        </div>
        <h3 className="mainHeading mb-5">Registration</h3>
        <Typography
          style={{
            margin: "0 auto",
            color: "#fff",
            fontSize: "19px",
            textAlign: "left",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              onClick={() =>
                navigate("/signup-personal", {
                  state: location?.state,
                })
              }
              style={{
                borderRadius: "50%",
                width: "33px",
                height: "33px",
                border: "1px solid white",
                backgroundColor: "#35C203",
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  padding: "2px",
                }}
              >
                1
              </span>
            </div>
            <div
              style={{
                border: "1px solid #333333AB",
                width: "300px",
                height: "0",
                alignItems: "center",
                margin: "15px 0",
              }}
            ></div>
            <div
              style={{
                borderRadius: "50%",
                width: "33px",
                height: "33px",
                // border: "1px solid black",
                color: "#fff",
                backgroundColor: "#35C203",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  padding: "2px",
                }}
              >
                2
              </span>
            </div>
            <div
              style={{
                border: "1px solid #333333AB",
                width: "300px",
                height: "0",
                alignItems: "center",
                margin: "15px 0",
              }}
            ></div>
            <div
              style={{
                borderRadius: "50%",
                width: "33px",
                height: "33px",
                border: "1px solid black",
                // backgroundColor: "#35C203",
                color: "black",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  padding: "2px",
                }}
              >
                3
              </span>
            </div>
            <div
              style={{
                border: "1px solid #333333AB",
                width: "300px",
                height: "0",
                alignItems: "center",
                margin: "15px 0",
              }}
            ></div>
            <div
              style={{
                borderRadius: "50%",
                width: "33px",
                height: "33px",
                border: "1px solid black",
                // backgroundColor: "#35C203",
                color: "black",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  padding: "2px",
                }}
              >
                4
              </span>
            </div>
          </div>
        </Typography>
        <div className="personalInfo">
          <h3>Permanent Address</h3>
        </div>

        <div className="infoContainer">
          <div>
            <label htmlFor="">Atoll</label>
            <TextField
              id="outlined-basic"
              select
              onChange={handleChangePermanent}
              value={permanentAddress?.Atoll}
              name="Atoll"
            >
              {atolls?.map((el) => {
                return (
                  <MenuItem
                    value={el?.atollName}
                    onClick={() => {
                      getIslandBasedOnAtoll({ atollCode: el?.atollCode }).then(
                        (res) => {
                          setIslands({ ...Islands, permanent: res?.result });
                        }
                      );
                    }}
                  >
                    {el?.atollName}
                  </MenuItem>
                );
              })}
            </TextField>
            <label htmlFor="">House No.</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter the House/Flat No."
              onChange={handleChangePermanent}
              value={permanentAddress?.HouseNo}
              name="HouseNo"
            />
            <label htmlFor="">City</label>
            <TextField
              id="outlined"
              placeholder="Enter the city name"
              onChange={handleChangePermanent}
              value={permanentAddress?.City}
              name="City"
            />
          </div>
          <div>
            <label htmlFor="">Islands</label>
            <TextField
              id="outlined-basic"
              select
              onChange={handleChangePermanent}
              value={permanentAddress?.Island}
              name="Island"
            >
              {/* {Atolls(permanentAddress?.Atolls?.code, true)?.islands?.map(
                (el) => {
                  return <MenuItem value={el?.name}>{el?.name}</MenuItem>;
                }
              )} */}
              {Islands?.permanent?.map((el) => {
                return (
                  <MenuItem value={el?.islandName}>{el?.islandName}</MenuItem>
                );
              })}
            </TextField>
            <label htmlFor="">Street</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter the Street Name"
              onChange={handleChangePermanent}
              value={permanentAddress?.Street}
              name="Street"
            />
            <label htmlFor="">Postal/Zip Code</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter the Postal/Zip Code"
              onChange={handleChangePermanent}
              value={permanentAddress?.Zip}
              name="Zip"
            />
            {/* <label htmlFor="">Postal/Zip Code</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter the Postal/Zip Code"
              onChange={handleChangePermanent}
              value={permanentAddress?.Zip}
              name="Zip"
            /> */}
            {/* <label htmlFor="">Country</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter the Country Name"
              onChange={handleChangePermanent}
              value={permanentAddress?.Country}
              name="Country"
            /> */}
          </div>
        </div>
        <div className="personalInfo">
          <h3>Current Address</h3>
        </div>
        <div className="checkbox-div w-[80%] flex mx-auto items-center">
          <Checkbox onChange={() => setSame(!same)} />
          <label className="text-[#333333B8] flex justify-start">
            Same as permanent address
          </label>
        </div>

        <div className="infoContainer">
          <div>
            <label htmlFor="">Atoll</label>
            <TextField
              id="outlined-basic"
              select
              onChange={handleChangeCurrent}
              value={currentAddress?.Atoll}
              name="Atoll"
            >
              {atolls?.map((el) => {
                return (
                  <MenuItem
                    value={el?.atollName}
                    onClick={() => {
                      getIslandBasedOnAtoll({ atollCode: el?.atollCode }).then(
                        (res) => {
                          setIslands({ ...Islands, current: res?.result });
                        }
                      );
                    }}
                  >
                    {el?.atollName}
                  </MenuItem>
                );
              })}
            </TextField>
            <label htmlFor="">House No.</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter the House/Flat No."
              onChange={handleChangeCurrent}
              value={currentAddress?.HouseNo}
              name="HouseNo"
            />
            <label htmlFor="">City</label>
            <TextField
              id="outlined"
              placeholder="Enter the city name"
              onChange={handleChangeCurrent}
              value={currentAddress?.City}
              name="City"
            />
          </div>
          <div>
            <label htmlFor="">Islands</label>
            <TextField
              id="outlined-basic"
              select
              onChange={handleChangeCurrent}
              value={currentAddress?.Island}
              name="Island"
            >
              {same
                ? Islands?.permanent.map((el) => {
                    return (
                      <MenuItem value={el?.islandName}>
                        {el?.islandName}
                      </MenuItem>
                    );
                  })
                : Islands?.current?.map((el) => {
                    return (
                      <MenuItem value={el?.islandName}>
                        {el?.islandName}
                      </MenuItem>
                    );
                  })}
            </TextField>
            <label htmlFor="">Street</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter the Street Name"
              onChange={handleChangeCurrent}
              value={currentAddress?.Street}
              name="Street"
            />
            <label htmlFor="">Postal/Zip Code</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter the Postal/Zip Code"
              onChange={handleChangeCurrent}
              value={currentAddress?.Zip}
              name="Zip"
            />
          </div>
        </div>
        <div className="btnBox flex justify-center my-5 gap-5">
          <button
            className="back-btn"
            onClick={() =>
              navigate("/signup-personal", {
                state: location?.state,
              })
            }
          >
            Back
          </button>
          <button onClick={handleSubmit} className="btn">
            Next
          </button>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default AddressDetails;
