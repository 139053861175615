import React, { useEffect, useState } from "react";
import {
  getSetUpDateAndTime,
  setDateAndTime,
} from "../../services/setUp.service";
import styles from "../ShareData/share.module.css";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import Switch from "@mui/material/Switch";

function SetTime() {
  
  const [date, setDate] = useState({
    startDateAndTime: "",
    endDateAndTime: "",
  });

  const [setup, setSetUp] = useState();
  const [time, setTime] = useState();

  const handleSubmit = () => {
    let payload = {
      ...date,
      timeFrame: time,
    };

    setDateAndTime(payload).then(({ data }) => {
      if (data) {
        toast.success(data?.message);
      }
    });
  };
  useEffect(() => {
    getSetUpDateAndTime().then((res) => {
      if (res?.data) {
        setSetUp(res?.data?.result);
        setTime(res?.data?.result?.timeFrame);
      }
    });
  }, []);

  return (
    <div>
      <h1 className={styles.head}>Set Schedule</h1>
      <div className="w-[50%] m-auto mt-2">
        <div className="flex items-center">
          <p>Allow for all time</p>
          <Switch checked={time} onChange={() => setTime(!time)} />
          <p>Set Time</p>
        </div>
        {time && (
          <>
            <div className="flex items-center justify-between mt-2">
              <p>Select Start Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DateTimePicker", "DateTimePicker"]}
                >
                  <DateTimePicker
                    label="Select State Date And Time"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    defaultValue={dayjs(setup?.startDateAndTime)}
                    onChange={(dt) => {
                      const dateObject = new Date(dt?.$d);
                      const isoDateString = dateObject.toISOString();
                      setDate({
                        ...date,
                        startDateAndTime: isoDateString,
                      });
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div className="flex items-center justify-between mt-6">
              <p>Select End Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DateTimePicker", "DateTimePicker"]}
                >
                  <DateTimePicker
                    label="Select End Date And Time"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    defaultValue={dayjs(setup?.endDateAndTime)}
                    onChange={(dt) => {
                      const dateObject = new Date(dt?.$d);
                      const isoDateString = dateObject.toISOString();
                      setDate({
                        ...date,
                        endDateAndTime: isoDateString,
                      });
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </>
        )}
        <Button
          sx={{
            marginLeft: "70%",
            marginTop: "2rem",
          }}
          variant="contained"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default SetTime;
