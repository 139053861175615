import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginService } from "../../services/http.service";
import styles from "./signin.module.css";
import { FormHelperText } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mtdc.com.mv/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const SignIn = ({ setAuthenticated }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [errors, setErrors] = React.useState({
    NationalId: false,
    Email: false,
    Password: false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const payload = {
      Email: data.get("email"),
      Password: data.get("password"),
      NationalID: data.get("id"),
    };
    if (!validation(data)) {
      await loginService(payload).then((res) => {
        if (res?.data?.status == "OK") {
          console.log("hit");
          toast.success(res?.data?.message);
          setAuthenticated(true);
          navigate(`/otp/${res?.data?.data?.phone_number}`, {
            state: {
              email: payload?.Email,
              type: "login",
            },
          });
          // localStorage.setItem("token", res.data.accessToken);

          // if (res?.data?.result?.Type == "proxy") {
          //   if (res?.data?.result?.Status == "Inprogress") {
          //     console.log(1);
          //     navigate("/update-details");
          //   } else {
          //     console.log(2);
          //     navigate("/dashboard/agm");
          //   }
          // } else if (res?.data?.result?.Type == "shareHolder") {
          //   if (res?.data?.result?.Status == "Inprogress") {
          //     navigate("/update-details");
          //   } else {
          //     navigate("/dashboard/app");
          //   }
          // } else {
          //   navigate("/dashboard/all-shareHolders");
          // }
          // window.location.reload();
        }
      });
    }
  };

  const validation = (data) => {
    let status = false;
    let errorCheck = {
      NationalId: false,
      Email: false,
      Password: false,
    };
    if (data.get("id").length < 5) {
      status = true;
      errorCheck.NationalId = true;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.get("email"))
    ) {
      status = true;
      errorCheck.Email = true;
    } else if (data.get("password").length < 8) {
      status = true;
      errorCheck.Password = true;
    }
    setErrors(errorCheck);
    return status;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <Grid container component="main" sx={{ height: "100vh" }}> */}
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={styles.main}
        sx={{
          backgroundImage: "url(/images/signup_bgimage.jpeg)",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          backgroundPosition: "center",
          gap: "1rem",
        }}
      >
        <div className={styles.logo}>
          <img src="/images/mtdc_logo.png" alt="" />
        </div>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            margin: "0 auto",
          }}
          className={styles.registerBx}
          elevation={6}
          square
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            className={styles.boxForm}
          >
            <Typography
              style={{
                padding: "0.5rem 0",
                color: "#fff",
                fontSize: "19px",
                textAlign: "center",
                fontWeight: "400",
                lineHeight: "normal",
                textDecorationLine: "underline",
              }}
            >
              Sign in
            </Typography>

            <TextField
              size="small"
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              margin="normal"
              required
              fullWidth
              id="id"
              label="National ID"
              placeholder="National ID*"
              name="id"
              autoComplete="id"
              autoFocus
              variant="filled"
              error={errors.NationalId}
              helperText={
                errors.NationalId ? (
                  <FormHelperText>
                    National Id length should be atleast 5 character
                  </FormHelperText>
                ) : null
              }
            />
            <TextField
              className={styles.inputField}
              size="small"
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              margin="normal"
              required
              fullWidth
              id="email"
              variant="filled"
              label="Email"
              name="email"
              placeholder="Enter your email"
              type={"email"}
              autoComplete="email"
              autoFocus
              error={errors.Email}
              helperText={
                errors.Email ? (
                  <FormHelperText>Email is not correct</FormHelperText>
                ) : null
              }
            />
            <TextField
              className={styles.inputField}
              size="small"
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                  fontStyle: "Poppins",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Enter your password"
              margin="normal"
              required
              fullWidth
              id="password"
              variant="filled"
              label="Password"
              name="password"
              autoComplete="email"
              type={showPassword ? "text" : "password"}
              autoFocus
              error={errors.Password}
              helperText={
                errors.Password ? (
                  <FormHelperText>
                    Password should be atleast 8 characters including alphabets,
                    numeric value and special character
                  </FormHelperText>
                ) : null
              }
            />

            <FormControlLabel
              control={<Checkbox value="remember" sx={{ color: "white" }} />}
              label="Remember me"
              sx={{ color: "white", fontSize: "14px", float: "left" }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mb: 2,
                bgcolor: "#22C25E",
                height: "2.5rem",
                fontSize: "16px",
                fontWeight: "bold",
                borderRadius: "9px",
              }}
            >
              Sign in
            </Button>
            <Grid container>
              {/* <Grid item xs>
                  <Link href="#" variant="body2" style={{color:"white"}}>
                    Forgot password?
                  </Link>
                </Grid> */}
              <Grid container sx={{ display: "flex" }}>
                <Grid item xs onClick={() => navigate("/forget")}>
                  <Link
                    className={styles.heading}
                    variant="body2"
                    sx={{ color: "white", float: "left", fontStyle: "Oswald" }}
                    style={{ color: "white", fontSize: "14px" }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid
                  item
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/signup-personal")}
                >
                  <Link
                    className={styles.heading}
                    variant="body2"
                    sx={{ color: "white" }}
                    style={{ color: "white", fontSize: "14px" }}
                  >
                    {"Don't have account?Register here"}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            {/* <Copyright sx={{ mt: 5, color:"white" }} /> */}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>

    // <ThemeProvider theme={defaultTheme}>
    //   <Grid container component="main" sx={{ height: "100vh" }}>
    //     <CssBaseline />
    //     <Grid
    //       item
    //       xs={false}
    //       sm={4}
    //       md={7}
    //       sx={{
    //         backgroundImage:
    //           "url(http://mtdc.com.mv/storage/W5z0OrfIN7NXiwcXUusGYN2zxQVlOWXSOWyDobJI.jpg)",
    //         backgroundRepeat: "no-repeat",
    //         width: "100%",
    //         backgroundColor: (t) =>
    //           t.palette.mode === "light"
    //             ? t.palette.grey[50]
    //             : t.palette.grey[900],
    //         backgroundSize: "cover",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         backgroundPosition: "center",
    //       }}
    //     >
    //       <div style={{ width: "40%", height: "50%" }}>
    //         <img
    //           src="/images/mtdc_latest.jpeg"
    //           alt=""
    //           style={{ width: "100%", height: "100%" }}
    //         />
    //       </div>
    //     </Grid>
    //     <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
    //       <Box
    //         sx={{
    //           my: 8,
    //           mx: 4,
    //           display: "flex",
    //           flexDirection: "column",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Avatar sx={{ m: 1, bgcolor: "#2E458A" }}>
    //           <LockOutlinedIcon />
    //         </Avatar>
    //         <Typography component="h1" variant="h5">
    //           Sign in
    //         </Typography>
    //         <Box
    //           component="form"
    //           noValidate
    //           onSubmit={handleSubmit}
    //           sx={{ mt: 1 }}
    //         >
    //           <TextField
    //             margin="normal"
    //             required
    //             fullWidth
    //             id="id"
    //             label="National ID "
    //             name="id"
    //             autoComplete="id"
    //             autoFocus
    //           />
    //           <TextField
    //             margin="normal"
    //             required
    //             fullWidth
    //             name="email"
    //             label="Email"
    //             type="email"
    //             id="number"
    //             autoComplete="current-password"
    //           />
    //           <TextField
    //             margin="normal"
    //             required
    //             fullWidth
    //             name="password"
    //             label="Password"
    //             type="password"
    //             id="password"
    //             autoComplete="current-password"
    //           />
    //           <FormControlLabel
    //             control={<Checkbox value="remember" color="primary" />}
    //             label="Remember me"
    //           />
    //           <Button
    //             type="submit"
    //             fullWidth
    //             variant="contained"
    //             sx={{ mt: 3, mb: 2, bgcolor: "#2E458A" }}
    //           >
    //             Sign In
    //           </Button>
    //           <Grid container>
    //             <Grid item xs>
    //               <Link href="#" variant="body2">
    //                 Forgot password?
    //               </Link>
    //             </Grid>
    //             <Grid
    //               item
    //               sx={{ cursor: "pointer" }}
    //               onClick={() => navigate("/signup")}
    //             >
    //               <Link variant="body2">
    //                 {"Don't have an account? Sign Up"}
    //               </Link>
    //             </Grid>
    //           </Grid>
    //           <Copyright sx={{ mt: 5 }} />
    //         </Box>
    //       </Box>
    //     </Grid>
    //   </Grid>
    // </ThemeProvider>
  );
};
