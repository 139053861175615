import React from "react";

function Export(props) {
  return (
    <button
      type="button"
      onClick={props.download}
      style={{
        display: "flex",
        alignItems : "center",
        justifyContent : "center",
        width: "100px",
        height: "2rem",
        display: "flex",
        justifyContent: "center",
        fontSize: "16px",
        cursor: "pointer",
        padding: 6,
        font: "bold",
        color: "skyblue",
        borderRadius: "5px",
        outline: "none",
        // boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        margin: "1rem 0 1rem 0"
         
        // marginBottom : "1rem",
      }}
      // className="inline-block w-28 px-6 py-2 border-2 font-bold border-sky-600 text-sky-500 text-xs
      //            leading-tight uppercase rounded-md hover:text-sky-700 hover:bg-opacity-5 focus:outline-none
      //             focus:ring-0 transition duration-150 ease-in-out"
    >
      Download
    </button>
  );
}

export default Export;
