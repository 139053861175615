import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import { Box, FormHelperText, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DropzoneArea } from "material-ui-dropzone";
import Button from "@mui/material/Button";
import {
  getCurrentUser,
  updateCurrentUser,
  updateProfileImage,
} from "../../services/user.service";
import Loader from "../../component/loader/Loader";
import { toast } from "react-toastify";
import { uploadImage } from "../../services/upload.service";
import moment from "moment";
function Profile() {
  const [data, setData] = useState();
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    Account_No: false,
    swift_code: false,
    mobile: false,
  });
  const getUserMe = () => {
    getCurrentUser().then(({ result }) => {
      setData(result);
    });
  };
  useEffect(() => {
    getUserMe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const formData = new FormData();
    formData.append("file", file);
    const isValidated = Validate(data);

    if (isValidated === true) {
      Promise.all([
        new Promise((resolve, reject) => {
          const response = uploadImage(formData);
          if (response) {
            resolve(response);
          } else {
            reject();
          }
        }),
      ]).then(async ([res1]) => {
        // console.log("check", res1);
        const payload = {
          Image: res1?.result,
        };
        updateProfileImage(payload).then((res) => {
          toast.success(res.message);
          getUserMe();
        });
      });
    }

    // const isValidated = Validate(data);
    // const payload = {
    //   Name: data.get("name"),r
    //   Email: data.get("email"),
    //   Account_No: data.get("number"),
    //   Swift_code: data.get("code"),
    //   Mobile: data.get("mobile"),
    // };
    // if (isValidated === true) {
    //   updateCurrentUser(payload).then((res) => {
    //     toast.success(res.message);
    //   });
    //   // console.log("Payload", payload);
    // }
  };

  const Validate = (data) => {
    let status = true;
    const errorCheck = {
      name: false,
      email: false,
      Account_No: false,
      swift_code: false,
      mobile: false,
    };
    if (data.get("name").length < 3) {
      errorCheck.name = true;
      status = false;
    } else if (data.get("email").length < 10) {
      errorCheck.email = true;
      status = false;
    } else if (data.get("number").length < 10) {
      errorCheck.Account_No = true;
      status = false;
    } else if (data.get("mobile").length != 10) {
      errorCheck.mobile = true;
      status = false;
    }
    setErrors(errorCheck);
    return status;
  };
  console.log("data", data);
  return (
    <>
      {data ? (
        <div className={styles.container}>
          <Box
            component="form"
            sx={{ m: 2, width: "100%" }}
            onSubmit={handleSubmit}
          >
            <Grid style={{ width: "48%" }}>
              <h1 className={styles.head}>Manage Account</h1>{" "}
              <label className={styles.image_box}>
                <input
                  id="dropzone-file"
                  name="awards"
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  className={styles.input}
                  hidden
                  onChange={(e) => setFile(e.target.files[0])}
                />
                {data?.Image ? (
                  <img
                    src={`${process.env.REACT_APP_UPI}/Images/${data?.Image}`}
                  />
                ) : (
                  ""
                )}
              </label>
            </Grid>
            <Grid className={styles.textContained}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                label="Name"
                id="name"
                defaultValue={data?.Name}
                autoComplete="current-password"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                label="E-mail"
                id="email"
                defaultValue={data?.Email}
                autoComplete="current-password"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <Grid className={styles.textContained}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="number"
                label="Account Number"
                type="number"
                id="number"
                defaultValue={data?.Account_No}
                autoComplete="current-password"
                error={errors.Account_No}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <Grid className={styles.textContained}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="id"
                label="National Id"
                type="id"
                id="id"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
                defaultValue={data?.NationalID}
                autoComplete="current-password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="mobile"
                label="Mobile N0."
                type="number"
                id="mobile"
                defaultValue={data?.Mobile}
                autoComplete="current-password"
                error={errors.mobile}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <Grid className={styles.textContained}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="id"
                label="DOB"
                type="id"
                id="id"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
                defaultValue={moment(data?.DOB).format("DD-MM-YYYY")}
                autoComplete="current-password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="Type"
                label="User Type"
                defaultValue={data?.Type}
                autoComplete="current-password"
                error={errors.mobile}
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <h3 className="m-4">Permanent Address</h3>
            <Grid className={styles.textContained}>
              <TextField
                id="outlined-basic"
                label="Atoll"
                fullWidth
                margin="normal"
                defaultValue={data?.Permanent_address?.Atoll}
                name="permanentAtoll"
                autoComplete="current-password"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
              <TextField
                id="outlined-basic"
                label="Island"
                fullWidth
                margin="normal"
                defaultValue={data?.Permanent_address?.Island}
                name="permanentIsland"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <Grid className={styles.textContained}>
              <TextField
                id="outlined-select-currency"
                label="House No."
                fullWidth
                margin="normal"
                defaultValue={data?.Permanent_address?.HouseNo}
                name="permanentHouseNo"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
              <TextField
                label="City"
                fullWidth
                margin="normal"
                id="outlined-select-currency"
                defaultValue={data?.Permanent_address?.City}
                name="permanentCity"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <Grid className={styles.textContained}>
              <TextField
                label="Street Name"
                fullWidth
                margin="normal"
                id="outlined-select-currency"
                defaultValue={data?.Permanent_address?.Street}
                name="permanentStreet"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
              <TextField
                label="Postal/ZIP Code"
                fullWidth
                margin="normal"
                id="outlined-select-currency"
                defaultValue={data?.Permanent_address?.Zip}
                name="permanentZip"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <h3 className="m-4">Current Address</h3>
            <Grid className={styles.textContained}>
              <TextField
                id="outlined-basic"
                label="Atoll"
                fullWidth
                margin="normal"
                defaultValue={data?.Current_address?.Atoll}
                name="permanentAtoll"
                autoComplete="current-password"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
              <TextField
                id="outlined-basic"
                label="Island"
                fullWidth
                margin="normal"
                defaultValue={data?.Current_address?.Island}
                name="permanentIsland"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <Grid className={styles.textContained}>
              <TextField
                id="outlined-select-currency"
                label="House No."
                defaultValue={data?.Current_address?.HouseNo}
                name="permanentHouseNo"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                margin="normal"
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
              <TextField
                label="City"
                id="outlined-select-currency"
                defaultValue={data?.Current_address?.City}
                name="permanentCity"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <Grid className={styles.textContained}>
              <TextField
                label="Street Name"
                id="outlined-select-currency"
                defaultValue={data?.Current_address?.Street}
                name="permanentStreet"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
              <TextField
                label="Postal/ZIP Code"
                id="outlined-select-currency"
                defaultValue={data?.Current_address?.Zip}
                name="permanentZip"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  bgcolor: "#EEEEEE",
                }}
              />
            </Grid>
            <div className={styles.docs} >
              <div>
                <p>Front copy of National ID Card</p>
                <img
                 src={
                  data?.National_proof_front
                    && `${process.env.REACT_APP_UPI}/Images/${data?.National_proof_front}`
                }
                  alt=""
                />
              </div>
              <div>
                <p>Back copy of National ID Card</p>
                <img
                  src={
                    data?.National_proof_back
                      && `${process.env.REACT_APP_UPI}/Images/${data?.National_proof_back}`
                  }
                  alt=""
                />
              </div>
              {data?.Type == "shareHolder" && (
                <div>
                  <p>Account Detail Slip</p>
                  <img
                    src={
                      data?.Bank_proof
                        && `${process.env.REACT_APP_UPI}/Images/${data?.Bank_proof}`
                    }
                    alt=""
                  />
                </div>
              )}
            </div>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Box>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Profile;
