import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = `${process.env.REACT_APP_UPI}/api`;
// axios.defaults.baseURL = "http://35.187.224.75:5004/api";

export const createService = async (payload) => {
  try {
    const data = await axios.post("/v1/user/signUp", payload);
    return data;
  } catch (err) {
    if (err.response.data.length > 1) {
      err?.response?.data?.map((el) => {
        toast.error(el.msg);
      });
    } else toast.error(err.response.data.message);
  }
};

export const loginService = async (payload) => {
  try {
    const data = await axios.post("/v1/user/login", payload);
    return data;
  } catch (err) {
    if (err.response.data.length > 1) {
      err?.response?.data?.map((el) => {
        toast.error(el.msg);
      });
    } else
      toast.error(err.response.data.message, {
        position: "top-center",
      });
  }
};
if (localStorage.getItem("token")) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("token")}`;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

// export const get
