// component
import SvgColor from "../../../component/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

export const navProxy = [
  {
    title: "AGM",
    path: "/dashboard/agm",
    icons: icon("ic_analytics"),
  },
];
export const navAdmin = [
  {
    title: "All ShareHolders",
    path: "/dashboard/all-shareHolders",
    icon: "/images/Dashboard.png",
  },
  {
    title: "All Proxies",
    path: "/dashboard/all-proxies",
    icon: "/images/register.png",
  },
  {
    title: "AGM Request",
    path: "/dashboard/agm-request",
    icon: "/images/register.png",
  },
  {
    title: "All Admins",
    path: "/dashboard/new-admin",
    icon: "/images/register.png",
  },
  {
    title: "Set Up",
    path: "/dashboard/set-up",
    icon: "/images/register.png",
  }
];

export const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: "/images/Dashboard.png",
  },
  {
    title: "Share Register Entities ",
    path: "/dashboard/shares",
    icon: "/images/register.png",
  },
  {
    title: "Dividend Details",
    path: "/dashboard/dividend",
    icon: "/images/dividend.png",
  },
  {
    title: "ShareHolder Ledger",
    path: "/dashboard/ledger",
    icon: "/images/ledger.png",
  },
  {
    title: "AGM",
    path: "/dashboard/agm",
    icon: "/images/agm.png",
  },
];
