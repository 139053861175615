import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components

// sections
import { AppWidgetSummary } from "../sections/@dashboard/app";
import { useContext, useEffect, useState } from "react";
import { getList } from "../services/data.service";
import { useNavigate } from "react-router-dom";
import Loader from "../component/loader/Loader";
import { AiFillIdcard } from "react-icons/ai";
import styles from "./ShareData/share.module.css";
import { Context } from "../App";
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [mtdcData, setMtdcData] = useState();
  const [loader, setLoader] = useState(true);
  const userData = useContext(Context);
  useEffect(() => {
    getList().then((res) => {
      if (res?.status == "OK") {
        setMtdcData(res?.result[0]);
        setLoader(false);
      }
    });
  }, []);
  // console.log("check", mtdcData)
  const navigate = useNavigate();

  return (
    <>
      <div>
        <h1 className={styles.head}>Welcome to MTDC ShareHolder Portal</h1>

        {!loader ? (
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="National Id "
                total={mtdcData?.NationalID || userData?.NationalID}
                color="error"
                icon={"mdi:home"}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Birth Certificate No"
                total={mtdcData?.BirthCertificateNo || "-"}
                color="info"
                icon={"mdi:card"}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              onClick={() => {
                navigate("/dashboard/shares");
              }}
            >
              <AppWidgetSummary
                title="Shares Count"
                total={mtdcData?.SharesCount || "-"}
                color="warning"
                icon={"mdi:account"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="ShareholderNo"
                total={mtdcData?.ShareholderNo || "-"}
                icon={"mdi:root"}
              />
            </Grid>
          </Grid>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
