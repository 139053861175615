import React from "react";
import styled, { css } from "styled-components";
import { Audio } from "react-loader-spinner";

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Audio
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      /> */}
      <img
        src="/images/MTDC.gif"
        alt=""
        style={{ width: "250px", height: "200px" }}
      />
    </div>
  );
}

export default Loader;
