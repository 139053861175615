import React, { useContext, useEffect, useState } from "react";
import styles from "./questionarie.module.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { themeJson } from "./theme";
import { AgmDetals, getUserProxy } from "../../services/proxy.service";
import { useLocation } from "react-router-dom";
import { addAnswer } from "../../services/questionarie.service";
import { toast } from "react-toastify";
import Loader from "../../component/loader/Loader";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { Context } from "../../App";
import Timer from "../../component/Timer/Timer";

function Questionarie() {
  const [data, setData] = useState();
  const [shareholders, setShareholders] = useState([]);
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [proxyData, setProxyData] = useState();
  const userData = useContext(Context);
  const [visible, setVisible] = useState(true);

  const abs =
    data &&
    data?.map((el) => {
      return {
        type: "boolean",
        name: `${el?.QuestionnaireNo}`,
        title: `${el?.Questionnaire}`,
        valueTrue: "Yes",
        valueFalse: "No",
        renderAs: "radio",
      };
    });

  const json = {
    elements: abs,
  };

  useEffect(() => {
    AgmDetals({
      id: location?.pathname?.split("questionarie")[1].slice(1),
    }).then((res) => {
      setLoader(true);
      setProxyData(res?.data?.result[0]);
      setData(res?.data?.result[0]?.shareholderQuestionnaireLines);
    });

    getUserProxy().then((res) => {
      const approved = res?.data?.result
        ?.map((el) => {
          if (el?.Status?.toLowerCase() == "approved") {
            return { nationalId: el?.ShareholderNationalID };
          }
        })
        .filter((notUndefined) => notUndefined !== undefined);
      if (approved?.length > 0) {
        if (userData?.Type == "shareHolder") {
          setShareholders(
            approved.concat({ nationalId: userData?.NationalID })
          );
        } else {
          setShareholders(approved);
        }
      }
    });
  }, [userData]);

  // console.log("shareholders", shareholders);

  const survey = new Model(json);
  // You can delete the line below if you do not use a customized theme
  survey.applyTheme(themeJson);
  survey.onComplete.add(async (sender, options) => {
    const payload = {
      questionsanswers: sender.jsonObj.elements.map((el) => {
        return {
          questionNo: el?.name,
          question: el?.title,
          answer: sender.data[el?.name],
        };
      }),
      agmNo: location?.pathname?.split("questionarie")[1].slice(1),
      shareHoldersId: shareholders,
    };
    // console.log("payload", payload);
    await addAnswer(payload).then((res) => {
      toast.success(res?.data?.message);
    });
  });

  return (
    <>
      {shareholders.length && (
        <div>
          {loader ? (
            <div className={styles.container}>
              <h1 className={styles.head}>Questions and Answers</h1>
              <div className={styles.remain}>
                <Timer proxyData={proxyData} setVisible={setVisible} />
              </div>
              {visible && visible ? (
                <Survey model={survey} />
              ) : (
                <div>Time Up</div>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      )}{" "}
      {userData?.Type == "proxy" && !shareholders?.length && (
        <div className={styles.UnAuthor}>
          Your are not a proxy of any Share Holder wait Untill Share Holder
          Approval. Thanks !
        </div>
      )}
    </>
  );
}

export default Questionarie;
