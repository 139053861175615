import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../ShareData/share.module.css";
import moment from "moment";
import Loader from "../../component/loader/Loader";
import { AgGridReact } from "ag-grid-react";
import Export from "../../utils/exports";
import { getAllUser, ProxyStatusUpdate } from "../../services/proxy.service";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Allproxies() {
  const [data, setData] = useState();
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const DateRenderer = ({ data }) => {
    return <div>{moment(data?.createdAt).format("DD-MM-YYYY")}</div>;
  };

  const ImageRenderer = (data) => {
    return (
      <div style={{ cursor: "pointer" }}>
        <a
          href={`${process.env.REACT_APP_UPI}/Images/${
            data?.colDef?.cellRendererParams?.type == "national_card"
              ? data?.data?.National_proof_front
                ? data?.data?.National_proof_front
                : data?.data?.National_proof
              : data?.data?.Bank_proof
          }`}
          target="_blank"
          download="myimage"
        >
          <img
            src={`${process.env.REACT_APP_UPI}/Images/${
              data?.colDef?.cellRendererParams?.type == "national_card"
              ? data?.data?.National_proof_front
              ? data?.data?.National_proof_front
              : data?.data?.National_proof
            : data?.data?.Bank_proof
            }`}
            className={styles.docImg}
          />
        </a>
      </div>
    );
  };
  const filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      cellValue = moment(cellValue).format("DD-MM-YYYY");
      const dateParts = cellValue.split("-");
      const cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );

      // Set time to midnight for comparison
      filterLocalDateAtMidnight.setHours(0, 0, 0, 0);
      cellDate.setHours(0, 0, 0, 0);

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const ActionContainer = ({ data }) => {
    const [status, setStatus] = React.useState(data?.Status);
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = useState();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = async (event) => {
      if (event?.target?.value == "Inprogress") {
        handleOpen();
      } else {
        setStatus(event?.target?.value);
        ProxyStatusUpdate({
          id: data?.NationalID,
          status: event?.target?.value,
        }).then(({ data }) => {
          if (data) {
            toast.success(data?.message);
            proxies();
          }
        });
      }
    };

    const handleSubmit = async () => {
      await ProxyStatusUpdate({
        id: data?.NationalID,
        status: "Inprogress",
        Comment: comment,
      }).then(({ data }) => {
        if (data) {
          toast.success(data?.message);
          proxies();
        }
      });
    };

    return (
      <div>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={status}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            <MenuItem value={"Pending"}>Pending</MenuItem>
            <MenuItem value={"Approved"}>Approve</MenuItem>
            <MenuItem value={"Rejected"}>Reject</MenuItem>
            <MenuItem value={"Inprogress"}>In-Progress</MenuItem>
          </Select>
          <FormHelperText>{data?.Status}</FormHelperText>
        </FormControl>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CloseIcon
              sx={{ fontSize: "30px", cursor: "pointer", marginLeft: "90%" }}
              onClick={handleClose}
            />
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Comment
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                sx={{ width: "100%" }}
                multiline
                rows={4}
                id="standard-basic"
                label="Comment"
                variant="standard"
                onChange={(e) => setComment(e.target.value)}
              />
            </Typography>
            <Button
              sx={{ mt: 2 }}
              disabled={!comment}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </div>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    // { headerName: "Dividend No.", field: "Dividend_Number", resizable: true },
    {
      headerName: "National Id",
      field: "NationalID",
      resizable: true,
    },
    {
      headerName: "Name",
      field: "Name",
      resizable: true,
    },
    {
      headerName: "Email",
      field: "Email",
      resizable: true,
    },
    { headerName: "Mobile", field: "Mobile", resizable: true,filter: false, },
    {
      headerName: "Account Number",
      field: "Account_No",
      resizable: true,
      filter: false,
    },
    {
      headerName: "National card Attachment",
      field: "National_proof",
      resizable: true,
      cellRenderer: ImageRenderer,
      filter: false,
      cellRendererParams: {
        type: "national_card",
      },
    },
    // {
    //   headerName: "Bank card Attachment",
    //   field: "Bank_proof",
    //   resizable: true,
    //   cellRenderer: ImageRenderer,
    //   cellRendererParams: {
    //     type: "bank_card",
    //   },
    // },
    {
      headerName: "Created At",
      field: "createdAt",
      resizable: true,
      cellRenderer: DateRenderer,
      filter: "agDateColumnFilter", // Ensure this is set to "agDateColumnFilter"
      filterParams: filterParams,
    },
    {
      headerName: "Comment",
      field: "Comment",
      resizable: true,
    },
    {
      headerName: "Status",
      field: "Status",
      resizable: true,
      cellRenderer: ({ data }) => {
        return (
          <div
            style={{
              color: data?.Status.toLowerCase() == "rejected" ? "red" : "green",
            }}
          >
            {data?.Status}
          </div>
        );
      },
    },
    {
      headerName: "Action",
      field: "DividendPerShare",
      resizable: true,
      cellRenderer: ActionContainer,
    },
  ]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    wrapText: true,
    // floatingFilter: true,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  const proxies = () => {
    getAllUser("proxy").then((res) => {
      setData(res?.result);
    });
  };
  useEffect(() => {
    proxies();
  }, []);
  return (
    <div>
      <h1 className={styles.head}>Proxies</h1>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Export download={onBtnExport} />
      </div>
      {data ? (
        <div id="myGrid" className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={data}
            domLayout={"autoHeight"}
            headerHeight={60}
            rowHeight={100}
            pagination={true}
            paginationPageSize={10}
            onRowClicked={(e) => {
              navigate(`/dashboard/user/${e?.data?._id}`);
            }}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Allproxies;
