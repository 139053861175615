import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SignIn } from "../pages/signIn/SignIn";
import { SignUp } from "../pages/signUp/SignUp";
import Registration from "../pages/signUp/Registration";
import { CreatePass } from "../pages/forget/CreatePass";
import { OTP } from "../pages/forget/OTP";
import { SetPass } from "../pages/forget/SetPass";
import PersonalDetails from "../pages/Register/PersonalDetails";
import UploadDocs from "../pages/Register/UploadDocs";
import AddressDetails from "../pages/Register/AddressDetails";
import Preview from "../pages/Register/Preview";
import { AdminSignIn } from "../pages/signIn/AdminSingin";

function PublicRoute() {
  const [authenticated, setAuthenticated] = useState(false);
  return (
    <div>
      <Routes>
        <Route
          path="/login"
          element={<SignIn setAuthenticated={setAuthenticated} />}
        />
        <Route path="/admin-login" element={<AdminSignIn />} />
        <Route path="/signup-personal" element={<PersonalDetails />} />
        <Route path="/signup-address" element={<AddressDetails />} />
        <Route path="/signup-docs" element={<UploadDocs />} />
        <Route path="/signup-preview" element={<Preview />} />
        {/* <Route path="/docs" element={<Registration />} /> */}
        <Route
          path="/forget"
          element={<CreatePass setAuthenticated={setAuthenticated} />}
        />
        {authenticated && (
          <Route
            path="/otp/:mob"
            element={<OTP setAuthenticated={setAuthenticated} />}
          />
        )}
        {authenticated && <Route path="/setpass/:mob" element={<SetPass />} />}
      </Routes>
    </div>
  );
}

export default PublicRoute;
