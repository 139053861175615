import React, { useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import { getDividend } from "../../services/data.service";
import moment from "moment";
import Loader from "../../component/loader/Loader";
import styles from "../ShareData/share.module.css";
import Export from "../../utils/exports";

function Dividend() {
  const [data, setData] = useState();
  const gridRef = useRef(null);
  const DateRenderer = (date) => {
    // console.log("date", date);
    return <div>{moment(date?.data?.AnnouncementDate).format("DD-MM-YYYY")}</div>;
  };
  const DateRendererDec = (date) => {
    // console.log("date", date);
    return <div>{moment(date?.data?.DividendDeclarationDate).format("DD-MM-YYYY")}</div>;
  };
  const [columnDefs, setColumnDefs] = useState([
    // { headerName: "Dividend No.", field: "Dividend_Number", resizable: true },
    {
      headerName: "Announcement Date",
      field: "AnnouncementDate",
      resizable: true,
      cellRenderer: DateRenderer,
    },
    {
      headerName: "Dividend Type",
      field: "DividendType",
      resizable: true,
    },
    { headerName: "Year", field: "Year", resizable: true },
    {
      headerName: "Dividend Declaration Date",
      field: "DividendDeclarationDate",
      resizable: true,
      cellRenderer: DateRendererDec,
    },
    {
      headerName: "Dividend Per Share",
      field: "DividendPerShare",
      resizable: true,
    },
  ]);
  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    // floatingFilter: true,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  useEffect(() => {
    getDividend().then((res) => {
      if (res?.status == "OK") {
        setData(res?.result);
      }
    });
  }, []);
  
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  return (
    <div>
      <h1 className={styles.head}>Dividend Details</h1>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Export download={onBtnExport} />
      </div>
      {data ? (
        <div
          id="myGrid"
          // style={{
          //   height: "80vh",
          //   width: "100%",
          // }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={data}
            domLayout={"autoHeight"}
            headerHeight={60}
            rowHeight={60}
            pagination={true}
            paginationPageSize={10}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Dividend;
