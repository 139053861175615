import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import { Link, useLocation } from "react-router-dom";
import styles from "./proxy.module.css";
import { createProxy } from "../../services/proxy.service";
import { toast } from "react-toastify";
import Loader from "../../component/loader/Loader";
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { uploadImage } from "../../services/upload.service";
// import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { Context } from "../../App";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    // minHeight: 200,
    height: "5rem !important",
    width: "100%",
    border: "2px dashed #bdbdbd",
    // border : '2px solid red',
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    position: "relative",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

function Proxy({ handleClose, agmNo }) {
  const userData = useContext(Context);
  const [type, setType] = useState("request");
  const [errors, setErrors] = useState({
    NationalId: false,
    fullName: false,
    email: false,
    mobile: false,
    file: false,
  });
  // console.log("userData", userData);

  const [file, setFile] = useState();
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const formData = new FormData();
    formData.append("file", file[0]);
    // uploadImage(formData).then((res) => {
    //   console.log("loda", res);
    // });
    if (!validation(data)) {
      setLoader(true);
      Promise.all([
        new Promise((resolve, reject) => {
          const response = uploadImage(formData);
          if (response) {
            resolve(response);
          } else {
            reject();
          }
        }),
      ]).then(async ([res1]) => {
        const paylaod = {
          agmNo: agmNo,
          [type == "assign" ? "proxyNationalId" : "shareHolderNationalId"]:
            data.get("id"),
          [type == "assign" ? "shareHolderNationalId" : "proxyNationalId"]:
            userData?.NationalID,
          proxyName: data.get("name"),
          message: data.get("message"),
          proxyEmail: data.get("email"),
          mobile: data.get("mobile"),
          proof: res1?.result,
          type: type,
        };

        createProxy(paylaod).then((res) => {
          if (res?.data?.message) {
            setLoader(false);
            toast.success(res?.data?.message);
          }
        });
      });
    }
  };

  const validation = (data) => {
    let status = false;
    let errorCheck = {
      NationalId: false,
      fullName: false,
      email: false,
    };
    if (file.length === 0) {
      status = true;
      errorCheck.file = true;
    } else if (data.get("id").length < 5) {
      status = true;
      errorCheck.NationalId = true;
    } else if (data.get("name").length < 3) {
      status = true;
      errorCheck.fullName = true;
    } else if (data.get("email").length < 10) {
      status = true;
      errorCheck.email = true;
    } else if (!data.get("mobile").match("^[0-9]{10}$")) {
      status = true;
      errorCheck.mobile = true;
    }

    setErrors(errorCheck);
    return status;
  };
  const classes = useStyles();

  return (
    <div className={styles.container}>
      {loader ? (
        <Loader />
      ) : (
        <Box
          component="form"
          className={styles.contained}
          noValidate
          sx={{ mt: 1 }}
          onSubmit={handleSubmit}
        >
          <Grid className={styles.main}>
            <Typography
              sx={{
                mb: 5,
                color: "rgba(0, 0, 0, 0.72)",
                fontWeight: 500,
                textDecoration: "underline",
                fontSize: "19px",
                width: "100%",
                textAlign: "center",
                fontFamily: "sans-serif",
              }}
            >
              {type == "assign" ? "Assign Proxies" : "Proxy Request Form"}
            </Typography>
            <CloseIcon
              sx={{ cursor: "pointer", marginRight: "-20px" }}
              onClick={handleClose}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <p>Proxy type* :</p>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                // onChange={handleChange}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                value={userData?.Type == "shareHolder" ? type : "request"}
                defaultValue={"request"}
                disabled={userData?.Type !== "shareHolder"}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value={"request"}>Request as Proxy</MenuItem>
                <MenuItem value={"assign"}>Assign a proxy</MenuItem>
              </Select>
              {/* <FormHelperText>{data?.Status}</FormHelperText> */}
            </FormControl>
          </Grid>

          <Grid
            style={{
              width: "180px",
              margin: "2rem 0 0 0",
            }}
          >
            <DropzoneArea
              filesLimit={1}
              showFileNames={true}
              // dropzoneClass={styles.myDropZone}
              dropzoneClass={classes.dropzone}
              dropzoneParagraphClass={styles.myDropZoneText}
              dropzoneText="National Id Card *"
              onChange={(files) => {
                setFile(files);
              }}
            />
            {errors.file && (
              <FormHelperText style={{ color: "red" }}>
                Please upload a file.
              </FormHelperText>
            )}
          </Grid>

          <TextField
            className={styles.textInp}
            margin="normal"
            required
            fullWidth
            id="id"
            label={
              type == "assign"
                ? "Proxy National ID "
                : "ShareHolder National ID "
            }
            name="id"
            autoComplete="id"
            autoFocus
            placeholder="A000000"
            error={errors.NationalId}
            helperText={
              errors.NationalId ? (
                <FormHelperText>
                  National Id length should be atleast 5 character
                </FormHelperText>
              ) : null
            }
          />
          <TextField
            className={styles.textInp}
            margin="normal"
            required
            fullWidth
            id="name"
            label={
              type == "assign" ? "Proxy Full Name" : "ShareHolder Full Name"
            }
            name="name"
            autoComplete="name"
            autoFocus
            error={errors.fullName}
            helperText={
              errors.fullName ? (
                <FormHelperText>Name is not correct</FormHelperText>
              ) : null
            }
          />
          <TextField
            margin="normal"
            multiline
            rows={3}
            required
            fullWidth
            id="message"
            label="Message"
            name="message"
            autoComplete="message"
            autoFocus
          />
          <TextField
            className={styles.textInp}
            margin="normal"
            required
            fullWidth
            name="email"
            label={
              type == "assign" ? "Proxy Email" : "ShareHolder Email"
            }
            type="email"
            id="number"
            autoComplete="current-password"
            error={errors.email}
            helperText={
              errors.email ? (
                <FormHelperText>email is not correct</FormHelperText>
              ) : null
            }
          />
          <TextField
            className={styles.textInp}
            margin="normal"
            required
            fullWidth
            name="mobile"
            label={
              type == "assign" ? "Proxy Mobile" : "ShareHolder Mobile"
            }
            type="number"
            id="number"
            autoComplete="current-password"
            error={errors.mobile}
            // helperText={
            //   errors.mobile ? (
            //     <FormHelperText style={{ color: "red" }}>
            //       Mobile Number should be 10 digits
            //     </FormHelperText>
            //   ) : null
            // }
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, bgcolor: "#2E458A" }}
          >
            {type == "assign" ? "Assign a Proxy" : "Request as Proxy"}
          </Button>
        </Box>
      )}
    </div>
  );
}

export default Proxy;
