import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DropzoneArea } from "material-ui-dropzone";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { createService } from "../../services/http.service";
import { toast } from "react-toastify";
import styles from "./signup.module.css";
import { uploadImage } from "../../services/upload.service";
import Loader from "../../component/loader/Loader";
import { makeStyles } from '@material-ui/core/styles';  



function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mtdc.com.mv/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  dropzone: {
    // minHeight: 200,
    height : "5rem !important",
    width: '100%',
    border: '2px dashed #bdbdbd',
    // border : '2px solid red',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
}));

const defaultTheme = createTheme();

function Registration() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState({
    national_proof: "",
    bank_proof: "",
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    // setLoader(true);
    // const data = new FormData(event.currentTarget);
    // const formData1 = new FormData();
    // const formData2 = new FormData();
    // formData1.append("file", file.national_proof);
    // formData2.append("file", file.bank_proof);
    // Promise.all([
    //   new Promise((resolve, reject) => {
    //     const response = uploadImage(formData1);
    //     if (response) {
    //       resolve(response);
    //     } else {
    //       reject();
    //     }
    //   }),
    //   new Promise((resolve, reject) => {
    //     const response = uploadImage(formData2);
    //     if (response) {
    //       resolve(response);
    //     } else {
    //       reject();
    //     }
    //   }),
    // ]).then(async ([res1, res2]) => {
    //   // console.log("res", res1.result, res2?.result);
    //   const payload = {
    //     Account_No: data.get("number"),
    //     Swift_code: data.get("code"),
    //     National_proof: res1?.result,
    //     Bank_proof: res2?.result,
    //     ...location.state.id,
    //   };
    //   await createService(payload)
    //     .then((res) => {
    //       setLoader(false);
    //       if (res?.data) {
    //         toast.success(res.data.message);
    //         navigate("/");
    //       }
    //     })
    //     .catch((err) => {
    //       setLoader(false);
    //     });
    // });
  };
  const classes = useStyles();
  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <Grid container component="main" sx={{ height: "100vh" }}> */}
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        // className={styles.main}
        sx={{
          backgroundImage: "url(/images/signup_bgimage.jpeg)",
          backgroundRepeat: "no-repeat",
          minWidth: "100%",
          minHeight: "100vh",
          paddingBottom: "2rem",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          paddingBottom: "2rem",
          alignItems: "center",
          backgroundPosition: "center",
          // gap: "1rem"
        }}
      >
        <div
          className={styles.logo}
          // style={{
          //   width: "100%",
          //   height: "100%",
          //   margin: "0.5rem auto",
          //   display: "flex",
          //   justifyContent: "center"
          // }}
        >
          <img src="/images/mtdc_logo.png" alt="" />
        </div>

        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          elevation={6}
          square
          sx={{ margin: "0 auto" }}
          className={styles.registerBx}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: "#2E458A" }}>
              <LockOutlinedIcon />
            </Avatar> */}

          {loader ? (
            <Loader />
          ) : (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ m: "1rem 0" }}
              className={styles.boxForm}
            >
              <Typography
                className={styles.heading}
                style={{
                  padding: "0.5rem 0",
                  color: "#fff",
                  fontSize: "19px",
                  textAlign: "center",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textDecorationLine: "underline",
                }}
              >
                Register Here
              </Typography>

              <Typography
                style={{
                  color: "#fff",
                  fontSize: "19px",
                  textAlign: "left",
                }}
              >
                Steps:
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      borderRadius: "50%",
                      width: "33px",
                      height: "33px",
                      border: "1px solid white",
                      backgroundColor: "#35C203",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "17px",
                        fontWeight: "600",
                        padding: "2px",
                      }}
                    >
                      1
                    </span>
                  </div>
                  <div
                    style={{
                      border: "1px solid white",
                      width: "100px",
                      height: "0",
                      alignItems: "center",
                      margin: "15px 0",
                    }}
                  ></div>

                  <div
                    style={{
                      borderRadius: "50%",
                      width: "33px",
                      height: "33px",
                      border: "1px solid white",
                      backgroundColor: "#35C203",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "17px",
                        fontWeight: "600",
                        padding: "2px",
                        color: "white",
                      }}
                    >
                      2
                    </span>
                  </div>
                </div>
              </Typography>

              <TextField
                size="small"
                className={styles.inputField}
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "black",
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontWeight: "500",
                    fontSize: "13px",
                  },
                }}
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="id"
                label="National ID"
                value={location?.state?.id.NationalID}
                // placeholder="National ID*"
                name="id"
                autoComplete="id"
                autoFocus
              />
              <Grid
                container
                mt={2}
                spacing={1}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid style={{ width: "48%" }}>
                  <DropzoneArea
                    filesLimit={1}
                    showFileNames={true}
                    // dropzoneClass={styles.myDropZone}
                    dropzoneClass={classes.dropzone}
                    dropzoneParagraphClass={styles.myDropZoneText}
                    dropzoneText="National Id Card *"
                    onChange={(files) => {
                      setFile({ ...file, national_proof: files[0] });
                    }}
                  />
                </Grid>
                <Grid style={{ width: "48%" }}>
                  <DropzoneArea
                    filesLimit={1}
                    showFileNames={true}
                    dropzoneClass={classes.dropzone}
                    dropzoneParagraphClass={styles.myDropZoneText}
                    dropzoneText="Bank Slip *"
                    onChange={(files) => {
                      setFile({ ...file, bank_proof: files[0] });
                    }}
                  />
                </Grid>
              </Grid>
              <TextField
                size="small"
                className={styles.inputField}
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "black",
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontWeight: "500",
                    fontSize: "13px",
                  },
                }}
                margin="normal"
                variant="filled"
                required
                fullWidth
                name="number"
                label="Account Number"
                type="number"
                id="number"
                // placeholder="National ID*"
                autoComplete="id"
                autoFocus
              />
              {/* <TextField
                size="small"
                className={styles.inputField}
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "black",
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontWeight: "500",
                    fontSize: "13px",
                  },
                }}
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="code"
                label="Swift Code"
                name="code"
                autoComplete="id"
                autoFocus
              /> */}
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoComplete="email"
                type="password"
                autoFocus
              /> */}

              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={styles.commonBtn}
                sx={{
                  mt: 2,
                  mb: 2,
                  bgcolor: "#22C25E",
                  fontSize: "21px",
                  fontWeight: "bold",
                  borderRadius: "9px",
                }}
              >
                Register
              </Button>
              <Grid container>
                {/* <Grid item xs>
                {/* <Link href="#" variant="body2">
                        Forgot password?
                    </Link> */}
                {/* </Grid> */}
                <Grid
                  item
                  className={styles.heading}
                  sx={{ cursor: "pointer", margin: "0 auto" }}
                  onClick={() => navigate("/")}
                >
                  <Link
                    style={{
                      color: "white",
                      fontSize: "14px",
                      lineHeight: "26px",
                      fontWeight: "100",
                    }}
                  >
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Registration;
