import React, { useEffect, useRef, useState } from "react";
import { LedgerDividend, getDividend } from "../../services/data.service";
import moment from "moment";
import Loader from "../../component/loader/Loader";
import { AgGridReact } from "ag-grid-react";
import styles from "../ShareData/share.module.css";
function Ledger() {
  const [data, setData] = useState();
  const gridApi = useRef(null);
  const DateRenderer = (date) => {
    return <div>{moment(date?.data?.PostingDate).format("DD-MM-YYYY")}</div>;
  };
  const [columnDefs, setColumnDefs] = useState([
    // { headerName: "Dividend No.", field: "Dividend_Number", resizable: true },
    { headerName: "Document Type", field: "DocumentType", resizable: true },
    {
      headerName: "Posting Date",
      field: "PostingDate",
      resizable: true,
      cellRenderer: DateRenderer,
    },
    {
      headerName: "ShareHolder Name",
      field: "ShareholderName",
      resizable: true,
    },
    {
      headerName: "Amount",
      field: "Amount",
      resizable: true,
    },
    {
      headerName: "Remaining Amount",
      field: "RemainingAmount",
      resizable: true,
    },
  ]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    // floatingFilter: true,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  useEffect(() => {
    LedgerDividend().then((res) => {
      if (res?.status == "OK") {
        setData(res?.result);
      }
    });
  }, []);

  useEffect(() => {
    if (gridApi.current) {
      const defaultFilterModel = {
        DocumentType: { type: "contains", filter: "Payment" },
      };
      gridApi.current.api.setFilterModel(defaultFilterModel);
    }
  }, []);

  return (
    <div>
      <h1 className={styles.head}>Share Holder Ledger</h1>
      {data ? (
        <div id="myGrid" className="ag-theme-alpine">
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={data}
            pagination={true}
            domLayout={"autoHeight"}
            paginationPageSize={10}
            headerHeight={60}
            rowHeight={60}
            onGridReady={(params) => {
              gridApi.current = params.api;
              // Apply default filter when grid is ready
              const defaultFilterModel = {
                DocumentType: { type: "contains", filter: "Payment" },
              };
              params.api.setFilterModel(defaultFilterModel);
            }}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Ledger;
