import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SignIn } from "../pages/signIn/SignIn";
import DashboardLayout from "../pages/dashboard/DashboardLayout";
import DashboardAppPage from "../pages/DashboardAppPage";
import ShareCounts from "../pages/ShareData/shareData";
import Dividend from "../pages/Dividend/Dividend";
import Ledger from "../pages/ledger/Ledger";
import Proxy from "../pages/proxy/Proxy";
import Profile from "../pages/profile/profile";
import Agm from "../pages/agm/Agm";
import Loader from "../component/loader/Loader";
import { Context } from "../App";
import GetAllproxies from "../pages/proxy/getAllproxies";
import Questionarie from "../pages/questionarie/Questionarie";
import AllUsers from "../pages/allusers/AllUsers";
import Allproxies from "../pages/allproxies/Allproxies";
import AgmRequest from "../pages/allproxies/AgmRequest"
import SingleUser from "../pages/allusers/SingleUser";
import Admin from "../pages/Admin/Admin";
import CreateAdmin from "../pages/Admin/CreateAdmin";
import Details from "../pages/profile/Details";
import ProxyData from "../pages/proxy/proxyData";
import SetTime from "../pages/setup/Setup";

function PrivateRoute() {
  const userData = useContext(Context);
  return (
    <div>
      <Routes>
        <Route path="/" element={<DashboardLayout />}>
          {userData?.Type == "shareHolder" && (
            <>
              <Route path="/app" element={<DashboardAppPage />} />
              <Route path="/shares" element={<ShareCounts />} />
              <Route path="/dividend" element={<Dividend />} />
              <Route path="/ledger" element={<Ledger />} />
            </>
          )}
          {userData?.Type == "admin" && (
            <>
              <Route path="/all-shareHolders" element={<AllUsers />} default />
              <Route path="/all-proxies" element={<Allproxies />} />
              <Route path = "/agm-request" element = {<AgmRequest/>}/>
              <Route path="/user/:id" element={<SingleUser />} />
              <Route path="/new-admin" element={<Admin />} />
              <Route path="/create-admin" element={<CreateAdmin />} />
              <Route path="/set-up" element={<SetTime />} />
            </>
          )}
          <Route path="/proxy/:add/:id" element={<Proxy />} />
          <Route path="/agm" element={<Agm />} />
          <Route path="/my-profile" element={<Profile />} />
          <Route path="/load" element={<Loader />} />
          <Route path="/proxies" element={<GetAllproxies />} />
          <Route path="/proxies/:id" element={<ProxyData />} />
          <Route path="/questionarie/:id" element={<Questionarie />} />
        </Route>
      </Routes>
    </div>
  );
}

export default PrivateRoute;
