import { toast } from "react-toastify";
import httpService from "./http.service";

export const getAllRequest = async () => {
  try {
    const data = await httpService.get("/v2/user/agm-requests");
    return data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateRequest = async (payload) => {
    // console.log("payload", payload)
  try {
    const dataupdate = await httpService.post(`/v2/user/update-request/${payload?.id}`, payload);
    return dataupdate;
  } catch (error) {
    toast.error(error.message);
  }
};
