import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../ShareData/share.module.css";
import Export from "../../utils/exports";
import { AgGridReact } from "ag-grid-react";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { getAllRequest, updateRequest } from "../../services/agm-request";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
require("moment-timezone");

function AgmRequest() {
  const gridRef = useRef(null);
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const [data, setData] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const ActionContainer = ({ data }) => {
    const [status, setStatus] = React.useState(data?.status);
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = useState();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = async (event) => {
      if (event?.target?.value == "Reject") {
        handleOpen();
      } else {
        setStatus(event?.target?.value);
        const payload = {
          status: event?.target?.value,
          id: data?._id,
        };
        updateRequest(payload).then((res) => {
          if (res?.data) {
            toast.success(res?.data?.message);
            getProxyRequests();
          }
        });
      }
    };
    const handleSubmit = async () => {
      // console.log("hit")
      const payload = {
        status: "Reject",
        id: data?._id,
        comment: comment,
      };
      updateRequest(payload).then((res) => {
        if (res?.data) {
          toast.success(res?.data?.message);
          getProxyRequests();
        }
      });
    };
    return (
      <div>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={status}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            <MenuItem value={"Pending"}>Pending</MenuItem>
            <MenuItem value={"Approved"}>Approve</MenuItem>
            <MenuItem value={"Reject"}>Reject</MenuItem>
          </Select>
          {/* <FormHelperText>{data?.Status}</FormHelperText> */}
        </FormControl>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <CloseIcon
              sx={{ fontSize: "30px", cursor: "pointer", marginLeft: "90%" }}
              onClick={handleClose}
            />
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Comment
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                sx={{ width: "100%" }}
                multiline
                rows={4}
                id="standard-basic"
                label="Comment"
                variant="standard"
                onChange={(e) => setComment(e.target.value)}
              />
            </Typography>
            <Button
              sx={{ mt: 2 }}
              disabled={!comment}
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </div>
    );
  };

  const ImageRenderer = (data) => {
    const fileUrl = `${process.env.REACT_APP_UPI}/Images/${data?.data?.proof}`;
    const fileType = data?.data?.proof?.split(".").pop()?.toLowerCase();

    const openInNewTab = (url) => {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    };

    return (
      <div style={{ cursor: "pointer" }}>
        <a href={fileUrl} target="_blank" download="myimage">
          <img
            src={fileType == "pdf" ? "/images/pdf.png" : fileUrl}
            className={fileType == "pdf" ? "w-[40px] h-[40px]" : styles.docImg}
            alt="NationId Proof"
          />
        </a>
      </div>
    );
  };

  const DateRenderer = ({ data }) => {
    return (
      <div>
        {moment(data?.createdAt)
          .tz("Indian/Maldives")
          .format("DD-MM-YYYY | hh:mm:ss A")}
      </div>
    );
  };

  const filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      cellValue = moment(cellValue).format("DD-MM-YYYY");
      const dateParts = cellValue.split("-");
      const cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );

      // Set time to midnight for comparison
      filterLocalDateAtMidnight.setHours(0, 0, 0, 0);
      cellDate.setHours(0, 0, 0, 0);

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const [columnDefs, setColumnDefs] = useState([
    // { headerName: "Dividend No.", field: "Dividend_Number", resizable: true },
    {
      headerName: "ShareHolder National Id",
      field: "shareHolderNationalId",
      resizable: true,
    },
    {
      headerName: "ShareHolder Name",
      field: "Name",
      resizable: true,
      valueGetter: (params) => {
        if (params.data.type == "request") {
          return params.data.Name;
        } else {
          return params.data?.shareHolderData[0]?.Name;
        }
      },
    },
    {
      headerName: "ShareHolder Email",
      field: "Email",
      resizable: true,
      valueGetter: (params) => {
        if (params.data.type == "request") {
          return params.data.Email;
        } else {
          return params.data?.shareHolderData[0]?.Email;
        }
      },
    },
    // { headerName: "ShareHolder Mobile", field: "Mobile", resizable: true },
    {
      headerName: "Proxy National Id",
      field: "proxyNationalId",
      resizable: true,
    },
    {
      headerName: "Proxy Name",
      field: "proxyData.Name",
      resizable: true,
      valueGetter: (params) => {
        if (params.data.type == "request") {
          return params.data.proxyData.Name;
        } else {
          return params.data.Name;
        }
      },
    },
    {
      headerName: "Proxy Email",
      field: "proxyData.Email",
      resizable: true,
      valueGetter: (params) => {
        if (params.data.type == "request") {
          return params.data.proxyData.Email;
        } else {
          return params?.data?.Email;
        }
      },
    },
    {
      headerName: "ShareHolder Mobile",
      field: "mobile",
      resizable: true,
      valueGetter: (params) => {
        if (params.data.type == "request") {
          return params.data.mobile;
        } else {
          return params.data?.shareHolderData[0]?.Name;
        }
      },
    },
    {
      headerName: "Proxy Mobile",
      field: "proxyData.Mobile",
      resizable: true,
      valueGetter: (params) => {
        if (params.data.type == "request") {
          return params.data.proxyData.Mobile;
        } else {
          return params.data.mobile;
        }
      },
    },

    {
      headerName: "National card Attachment",
      field: "proof",
      resizable: true,
      cellRenderer: ImageRenderer,
    },

    {
      headerName: "Created At",
      field: "createdAt",
      resizable: true,
      minWidth: "200",
      cellRenderer: DateRenderer,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
    {
      headerName: "Type",
      field: "type",
      resizable: true,
      cellRenderer: ({ data }) => {
        return <div>{data?.type?.toUpperCase()}</div>;
      },
    },
    {
      headerName: "Message",
      field: "Message",
      resizable: true,
      cellRenderer: ({ data }) => {
        return <div>{data?.Message}</div>;
      },
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      cellRenderer: ({ data }) => {
        return (
          <div
            style={{
              color:
                data?.status?.toLowerCase() == "rejected" ? "red" : "green",
            }}
          >
            {data?.status}
          </div>
        );
      },
    },
    {
      headerName: "Comment",
      field: "comment",
      resizable: true,
      cellRenderer: ({ data }) => {
        return (
          <p>
            <Tooltip title={data?.comment}>{data?.comment}</Tooltip>
          </p>
        );
      },
    },
    {
      headerName: "Action",
      field: "",
      resizable: true,
      cellRenderer: ActionContainer,
    },
  ]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    wrapText: true,
    // floatingFilter: true,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  const getProxyRequests = () => {
    getAllRequest().then((res) => {
      if (res?.data) {
        setData(res?.data?.result);
      }
    });
  };

  useEffect(() => {
    getProxyRequests();
  }, []);

  return (
    <div>
      <h1 className={styles.head}>AGM Requests</h1>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Export download={onBtnExport} />
      </div>
      <div id="myGrid" className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={data}
          domLayout={"autoHeight"}
          headerHeight={60}
          rowHeight={100}
          pagination={true}
          paginationPageSize={10}
          // onRowClicked={(e) => {
          //   navigate(`/dashboard/user/${e?.data?._id}`);
          // }}
        />
      </div>
    </div>
  );
}

export default AgmRequest;
