import React, {useContext, useEffect} from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {toast} from "react-toastify";

const NonLoginGuard = () => {
    let auth = !!localStorage.getItem('token');
    useEffect(() => {
        if (auth) {
            toast.info('Welcome back', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }, [auth]);


    return (
        auth ? <Navigate to='/dashboard'/>  : <Outlet/>
    )
};

export default NonLoginGuard;
