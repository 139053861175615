import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../ShareData/share.module.css";
import Export from "../../utils/exports";
import style from "./all.module.css";
import { useParams } from "react-router-dom";
import { getSingleUser } from "../../services/user.service";
import Loader from "../../component/loader/Loader";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ProxyStatusUpdate } from "../../services/proxy.service";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const styled = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SingleUser() {
  const gridRef = useRef(null);
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState();
  const [loader, setLoader] = useState(true);
  const [status, setStatus] = useState();
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const getUserInfo = (id) => {
    getSingleUser({ id }).then((res) => {
      if (res?.result) {
        setUserInfo(res?.result);
        setStatus(res?.result?.Status);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    getUserInfo(id);
  }, [id]);

  const handleChange = async (event) => {
    if (event?.target?.value == "Inprogress") {
      handleOpen();
    } else {
      setStatus(event?.target?.value);
      ProxyStatusUpdate({
        id: userInfo?.NationalID,
        status: event?.target?.value,
      }).then(({ data }) => {
        if (data) {
          toast.success(data?.message);
          getUserInfo(id);
        }
      });
    }
  };
  const handleSubmit = async () => {
    await ProxyStatusUpdate({
      id: userInfo?.NationalID,
      status: "Inprogress",
      Comment: comment,
    }).then(({ data }) => {
      if (data) {
        toast.success(data?.message);
        getUserInfo(id);
        handleClose();
      }
    });
  };
  
  // console.log("userInfo", userInfo);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <h1 className={styles.head}>User Information</h1>
          <div className={style.main}>
            <p>
              National Id: <span> #{userInfo?.NationalID}</span>
            </p>
            <div className={style.status}>
              Status
              {userInfo?.Type == "proxy" && (
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={status}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"Approved"}>Approve</MenuItem>
                    <MenuItem value={"Rejected"}>Reject</MenuItem>
                    <MenuItem value={"Inprogress"}>In-Progress</MenuItem>
                  </Select>
                </FormControl>
              )}
              {userInfo?.Type == "shareHolder" && (
                <p style={{ color: status == "Approved" ? "green" : "red" }}>
                  : {status?.toUpperCase()}
                </p>
              )}
            </div>
          </div>
          <div className={style.info}>
            <div className={style.personalInfo}>
              <h3>Personal Details</h3>
              <div className={style.infoContainer}>
                <div>
                  <label htmlFor="">User Type</label>
                  <p>{userInfo?.Type?.toUpperCase()}</p>
                  <label htmlFor="">Name</label>
                  <p>{userInfo?.Name}</p>
                  <label htmlFor="">DOB</label>
                  <p>{moment(userInfo?.DOB).format("DD/MM/YYYY") || "-"}</p>
                  <label htmlFor="">Name of Guardians</label>
                  <p>{userInfo?.Guardian_info?.Parent_name || "-"}</p>
                  <label htmlFor="">Relation</label>
                  <p>{userInfo?.Guardian_info?.Relation || "-"}</p>
                  <label htmlFor="">Email</label>
                  <p>{userInfo?.Email || "-"}</p>
                </div>
                <div>
                  <label htmlFor="">National ID</label>
                  <p>{userInfo?.NationalID}</p>
                  <label htmlFor="">Contact No.</label>
                  <p>{userInfo?.Mobile}</p>
                  <label htmlFor="">Guardian's ID</label>
                  <p>{userInfo?.Guardian_info?.Parent_NationalID || "-"}</p>
                  <label htmlFor="">Guardian's Contact</label>
                  <p>{userInfo?.Guardian_info?.Contact || "-"}</p>
                </div>
              </div>
            </div>
            {userInfo?.Type !== "admin" && (
              <>
                <div className={style.personalInfo}>
                  <h3>Address Details</h3>
                  <div className={style.infoContainer}>
                    <div>
                      <h3>Permanent Address</h3>
                      <p></p>
                      <label htmlFor="">Type</label>
                      <p>{userInfo?.Permanent_address?.Region || "-"}</p>
                      <label htmlFor="">Address</label>
                      <p>
                        {userInfo?.Permanent_address?.HouseNo +
                          ", " +
                          userInfo?.Permanent_address?.Street +
                          ", " +
                          userInfo?.Permanent_address?.City +
                          ", " +
                          userInfo?.Permanent_address?.Country || "-"}{" "}
                      </p>
                    </div>
                    <div>
                      <h3>Current Address</h3>
                      <p></p>
                      <label htmlFor="">Type</label>
                      <p>{userInfo?.Current_address?.Region || "-"}</p>
                      <label htmlFor="">Address</label>
                      <p>
                        {userInfo?.Current_address?.HouseNo +
                          ", " +
                          userInfo?.Current_address?.Street +
                          ", " +
                          userInfo?.Current_address?.City +
                          ", " +
                          userInfo?.Current_address?.Country || "-"}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={style.personalInfo}>
                  <h3>Uploaded Documents</h3>
                  <div className={style.docs}>
                    <div>
                      <p>Front copy of National ID Card</p>
                      <a
                        href={`${process.env.REACT_APP_UPI}/Images/${userInfo?.National_proof_front}`}
                        target="_blank"
                        download="myimage"
                      >
                        <img
                          src={`${process.env.REACT_APP_UPI}/Images/${
                            userInfo?.National_proof_front
                              ? userInfo?.National_proof_front
                              : userInfo?.National_proof
                          }`}
                          alt=""
                        />
                      </a>
                    </div>
                    <div>
                      <p>Back copy of National ID Card</p>
                      <a
                        href={`${process.env.REACT_APP_UPI}/Images/${userInfo?.National_proof_back}`}
                        target="_blank"
                        download="myimage"
                      >
                        <img
                          // onClick={() =>
                          //   `${process.env.REACT_APP_UPI}/Images/${userInfo?.National_proof_back}`
                          // }
                          src={`${process.env.REACT_APP_UPI}/Images/${
                            userInfo?.National_proof_back
                              ? userInfo?.National_proof_back
                              : userInfo?.National_proof
                          }`}
                          alt=""
                        />
                      </a>
                    </div>
                    {userInfo?.Type !== "proxy" && (
                      <div>
                        <p>Account Detail Slip</p>
                        <a
                          href={`${process.env.REACT_APP_UPI}/Images/${userInfo?.Bank_proof}`}
                          target="_blank"
                          download="myimage"
                        >
                          <img
                            src={`${process.env.REACT_APP_UPI}/Images/${userInfo?.Bank_proof}`}
                            alt=""
                          />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styled}>
              <CloseIcon
                sx={{ fontSize: "30px", cursor: "pointer", marginLeft: "90%" }}
                onClick={handleClose}
              />
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Comment
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <TextField
                  sx={{ width: "100%" }}
                  multiline
                  rows={4}
                  id="standard-basic"
                  label="Comment"
                  variant="standard"
                  onChange={(e) => setComment(e.target.value)}
                />
              </Typography>
              <Button
                sx={{ mt: 2 }}
                disabled={!comment}
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
}

export default SingleUser;
