import { toast } from "react-toastify";
import httpService from "./http.service";

export const createProxy = async (payload) => {
  try {
    const data = await httpService.post("/v2/proxy/add", payload);
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message || error?.message);
  }
};

export const getUserProxy = async () => {
  try {
    const data = await httpService.get("/v2/proxy/all");
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const AgmDetals = async (id) => {
  try {
    if (id) {
      const data = await httpService.post("/v2/proxy/singleAgm", id);
      return data;
    } else {
      const data = await httpService.get("/v2/proxy/getAgms");
      return data;
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getAllUser = async (userType) => {
  try {
    const { data: proxies } = await httpService.post("/v2/user/all-users", {
      userType: userType,
    });
    return proxies;
  } catch (error) {
    toast.error(error.message);
  }
};

export const ProxyStatusUpdate = async ({ id, status, Comment }) => {
  try {
    const data = await httpService.put(`/v1/user/${id}`, {
      Status: status,
      Comment: Comment,
    });
    return data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const userProxyTrack = async (payload) => {
  try {
    const data = await httpService.post("/v2/proxy/track", payload);
    return data;
  } catch (error) {
    toast.error(error.message);
  }
};
