import PropTypes from "prop-types";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
// @mui
import { Box, List, ListItemText } from "@mui/material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1,mt:1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        color: "#FFF",
        fontSize: "15px",
        fontWeight: 400,
        height: "2.5rem",
        "&.active": {
          color: "#FFF",
          background: "rgba(255, 255, 255, 0.31)",
          // fontWeight: "fontWeightBold",
        },
      }}
    >
      <StyledNavItemIcon><img src={icon} /></StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
      <ArrowRightIcon
        sx={{
          fontSize: "20px",
          color: "#32518F",
        }}
      />
    </StyledNavItem>
  );
}
