import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "../ShareData/share.module.css";
import Export from "../../utils/exports";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getAllUser } from "../../services/proxy.service";
import style from "./admin.module.css";
import { CiCirclePlus } from "react-icons/ci";

function Admin() {
  const [data, setData] = useState();
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const DateRenderer = ({ data }) => {
    return <div>{moment(data?.createdAt).format("DD-MM-YYYY")}</div>;
  };

  const filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      cellValue = moment(cellValue).format("DD-MM-YYYY");
      const dateParts = cellValue.split("-");
      const cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );

      // Set time to midnight for comparison
      filterLocalDateAtMidnight.setHours(0, 0, 0, 0);
      cellDate.setHours(0, 0, 0, 0);

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      
      return 0;
    },
  };
  
  const [columnDefs, setColumnDefs] = useState([
    // { headerName: "Dividend No.", field: "Dividend_Number", resizable: true },
    // {
    //   headerName: "National Id",
    //   field: "NationalID",
    //   resizable: true,
    // },
    {
      headerName: "Name",
      field: "Name",
      resizable: true,
    },
    {
      headerName: "Email",
      field: "Email",
      resizable: true,
    },
    { headerName: "Mobile", field: "Mobile", resizable: true , filter: false,},
    {
      headerName: "DOB",
      field: "DOB",
      resizable: true,
      filter: false,
      cellRenderer : DateRenderer,
      filter: "agDateColumnFilter", // Ensure this is set to "agDateColumnFilter"
      filterParams: filterParams,
    },
    {
      headerName: "Created At",
      field: "createdAt",
      resizable: true,
      cellRenderer: DateRenderer,
      filter: "agDateColumnFilter", // Ensure this is set to "agDateColumnFilter"
      filterParams: filterParams,
    },
  ]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    wrapText: true,
    // floatingFilter: true,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  useEffect(() => {
    getAllUser("admin").then((res) => {
      setData(res?.result);
    });
  }, []);
  return (
    <div>
      <h1 className={styles.head}>Admins</h1>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "5px",
          marginBottom: "1rem",
          marginTop : "1rem"
        }}
      >
        <div
          className={style.btn}
          onClick={() => navigate("/dashboard/create-admin")}
        >
          <p>Create New Admin</p>{" "}
          <span>
            <CiCirclePlus size={30} color="#FFF"/>
          </span>
        </div>
        <Export download={onBtnExport} />
      </div>
      <div id="myGrid" className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={data}
          domLayout={"autoHeight"}
          headerHeight={60}
          rowHeight={100}
          pagination={true}
          paginationPageSize={10}
          onRowClicked={(e) => {
            navigate(`/dashboard/user/${e?.data?._id}`);
          }}
        />
      </div>
    </div>
  );
}

export default Admin;
