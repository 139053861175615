import { Button, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import "./personaldetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../allusers/all.module.css";
import { createService } from "../../services/http.service";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import Loader from "../../component/loader/Loader";
import { uploadImage } from "../../services/upload.service";

function Preview() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState();
  const [check, setChecked] = useState(false);
  const {
    Account_No,
    Confirm,
    Contact,
    DOB,
    Email,
    Mobile,
    Name,
    NationalID,
    Parent_name,
    Relation,
    Parent_NationalID,
    Type,
    Password,
  } = location?.state?.personalDetails;

  const {
    Current_address,
    Permanent_address,
    Bank_proof,
    National_proof_back,
    National_proof_front,
  } = location?.state;

  console.log("check", National_proof_back);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const formData1 = new FormData();
    const formData2 = new FormData();
    const formData3 = new FormData();
    formData1.append("file", National_proof_front);
    formData2.append("file", National_proof_back);
    formData3.append("file", Bank_proof);
    setLoader(true);
    Promise.all([
      uploadImage(formData1),
      uploadImage(formData2),
      uploadImage(formData3),
    ]).then(async ([res1, res2, res3]) => {
      const payload = {
        Account_No,
        Confirm,
        DOB,
        Email,
        Mobile,
        Name,
        NationalID: NationalID.trim(),
        Guardian_info: { Parent_name, Relation, Parent_NationalID, Contact },
        Type,
        Current_address: Current_address,
        Permanent_address: Permanent_address,
        National_proof_front: res1?.result,
        National_proof_back: res2?.result,
        Bank_proof: Type == "shareHolder" ? res3?.result : null,
        Password,
        TermsCondition: check,
      };
// console.log("payload", payload)
      setLoader(true);
      await createService(payload).then((res) => {
        setLoader(false);
        if (res?.data) {
          toast?.success(res?.data?.message);
          navigate("/");
        }
      });
    });

    // const payload = {
    //   Account_No,
    //   Confirm,
    //   DOB,
    //   Email,
    //   Mobile,
    //   Name,
    //   NationalID,
    //   Guardian_info: { Parent_name, Relation, Parent_NationalID, Contact },
    //   Type,
    //   Current_address: Current_address,
    //   Permanent_address: Permanent_address,
    //   Bank_proof,
    //   National_proof_back,
    //   National_proof_front,
    //   Password,
    //   TermsCondition: check,
    // };

    // // setLoader(true);
    // await createService(payload).then((res) => {
    //   setLoader(false);
    //   if (res?.data) {
    //     toast?.success(res?.data?.message);
    //     navigate("/");
    //   }
    // });
  };

  return (
    <div className="main">
      <div className="logo-box mb-4 mt-0 w-[100%] h-auto flex justify-center items-center bg-[#CEDEFE]">
        <img
          src="/images/mtdc_logo.png"
          alt="image"
          style={{ height: "auto", width: "25%" }}
        />
      </div>
      <h3 className="mainHeading mb-5">Registration</h3>
      <Typography
        style={{
          margin: "0 auto",
          color: "#fff",
          fontSize: "19px",
          textAlign: "left",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            onClick={() =>
              navigate("/signup-personal", {
                state: location?.state,
              })
            }
            style={{
              borderRadius: "50%",
              width: "33px",
              height: "33px",
              border: "1px solid white",
              backgroundColor: "#35C203",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "17px",
                fontWeight: "600",
                padding: "2px",
              }}
            >
              1
            </span>
          </div>
          <div
            style={{
              border: "1px solid #333333AB",
              width: "300px",
              height: "0",
              alignItems: "center",
              margin: "15px 0",
            }}
          ></div>
          <div
            onClick={() =>
              navigate("/signup-address", {
                state: location?.state,
              })
            }
            style={{
              borderRadius: "50%",
              width: "33px",
              height: "33px",
              // border: "1px solid black",
              color: "#fff",
              backgroundColor: "#35C203",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "17px",
                fontWeight: "600",
                padding: "2px",
              }}
            >
              2
            </span>
          </div>
          <div
            style={{
              border: "1px solid #333333AB",
              width: "300px",
              height: "0",
              alignItems: "center",
              margin: "15px 0",
            }}
          ></div>
          <div
            onClick={() =>
              navigate("/signup-docs", {
                state: location?.state,
              })
            }
            style={{
              borderRadius: "50%",
              width: "33px",
              height: "33px",
              border: "1px solid black",
              backgroundColor: "#35C203",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "17px",
                fontWeight: "600",
                padding: "2px",
              }}
            >
              3
            </span>
          </div>
          <div
            style={{
              border: "1px solid #333333AB",
              width: "300px",
              height: "0",
              alignItems: "center",
              margin: "15px 0",
            }}
          ></div>
          <div
            style={{
              borderRadius: "50%",
              width: "33px",
              height: "33px",
              border: "1px solid black",
              backgroundColor: "#35C203",
              color: "#fff",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "17px",
                fontWeight: "600",
                padding: "2px",
              }}
            >
              4
            </span>
          </div>
        </div>
      </Typography>
      <div className="personalInfo">
        <h3>Preview</h3>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <>
          {location?.state && (
            <>
              <div style={{ width: "80%", margin: "auto" }}>
                <div className={style.info}>
                  <div className={style.personalInfo}>
                    <h3>Personal Details</h3>
                    <div className={style.infoContainer}>
                      <div>
                        <label htmlFor="">Type</label>
                        <p>{Type?.toUpperCase()}</p>
                        <label htmlFor="">Name</label>
                        <p>{Name}</p>
                        <label htmlFor="">DOB</label>
                        <p>{DOB}</p>
                        <label htmlFor="">Name of Guardians</label>
                        <p>{Parent_name || "-"}</p>
                        <label htmlFor="">Relation</label>
                        <p>{Relation || "-"}</p>
                        <label htmlFor="">Email</label>
                        <p>{Email}</p>
                        <label htmlFor="">Account Number</label>
                        <p>{Account_No}</p>
                      </div>
                      <div>
                        <label htmlFor="">National ID</label>
                        <p>{NationalID}</p>
                        <label htmlFor="">Contact No.</label>
                        <p>{Mobile}</p>
                        <label htmlFor="">Guardian's ID</label>
                        <p>{Parent_NationalID || "-"}</p>
                        <label htmlFor="">Guardian's Contact</label>
                        <p>{Contact || "-"}</p>
                      </div>
                    </div>
                  </div>
                  <>
                    <div className={style.personalInfo}>
                      <h3>Address Details</h3>
                      <div className={style.infoContainer}>
                        <div>
                          <h3>Permanent Address</h3>
                          <p></p>
                          <label htmlFor="">Atoll</label>
                          <p>{Permanent_address?.Atoll}</p>
                          <label htmlFor="">Island</label>
                          <p>{Permanent_address?.Island}</p>
                          <label htmlFor="">Address</label>
                          <p>
                            {Permanent_address?.HouseNo +
                              ", " +
                              Permanent_address?.Street +
                              ", " +
                              Permanent_address?.City +
                              ", " +
                              Permanent_address?.Country}{" "}
                          </p>
                        </div>
                        <div>
                          <h3>Current Address</h3>
                          <p></p>
                          <label htmlFor="">Atoll</label>
                          <p>{Current_address?.Atoll}</p>
                          <label htmlFor="">Island</label>
                          <p>{Current_address?.Island}</p>
                          <label htmlFor="">Address</label>
                          <p>
                            {Current_address?.HouseNo +
                              ", " +
                              Current_address?.Street +
                              ", " +
                              Current_address?.City +
                              ", " +
                              Current_address?.Country}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={style.personalInfo}>
                      <h3>Uploaded Documents</h3>
                      <div className={style.docs}>
                        <div>
                          <p>Front copy of National ID Card</p>

                          <img
                            // src={`}/Images/${National_proof_front}`}
                            src={
                              National_proof_front
                                ? URL.createObjectURL(National_proof_front)
                                : null
                            }
                            alt=""
                          />
                        </div>
                        <div>
                          <p>Back copy of National ID Card</p>
                          <img
                            src={
                              National_proof_back
                                ? URL.createObjectURL(National_proof_back)
                                : null
                            }
                            alt=""
                          />
                        </div>
                        {Type == "shareHolder" && (
                          <div>
                            <p>Account Detail Slip</p>
                            <img
                              src={
                                National_proof_back
                                  ? URL.createObjectURL(National_proof_back)
                                  : null
                              }
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                  <hr className="mt-[4rem] h-2" />
                  <div className="flex items-start">
                    <Checkbox
                      checked={check}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                      }}
                    />
                    <div>
                      <p className="font-bold text-sm">
                        I/We here by declare that:
                      </p>
                      {Type == "shareHolder" ? (
                        <ol className="text-sm">
                          <li>
                            Contact number provided by the shareholder will be
                            registered and used to send OTP for further login.
                          </li>
                          <li>
                            I Acknowledge that I have the full capacity and the
                            legal rights to enclose the information required.
                          </li>
                          <li>
                            I hereby agree to the information provided on this
                            portal is true and accurate with the supporting
                            documents.
                          </li>
                          <li>
                            I Acknowledge that MTDC have the right to reject or
                            terminate my application if the information provided
                            is inaccurate, not current, incomplete, or
                            misleading.
                          </li>
                        </ol>
                      ) : (
                        <ol className="text-sm">
                          <li>
                            I hereby agree that this Proxy application is
                            applied with the consent of the shareholder.
                          </li>
                          <li>
                            I am fully aware that all the proxy applications
                            will be verified by MTDC and MTDC has the right to
                            reject any application that does not receive the
                            consent of the shareholder.
                          </li>
                          <li>
                            MTDC can take legal action if /when necessary if any
                            information is provided without the shareholders'
                            consent.
                          </li>
                          <li>
                            I have read, understood, and agreed the to the Terms
                            and conditions mentioned above.
                          </li>
                        </ol>
                      )}
                    </div>
                  </div>
                  {/* <div className="flex items-center mt-2">
                <Checkbox />
                <p className="text-sm">
                  I have read and agree to the{" "}
                  <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline cursor-pointer">
                    terms and conditions.
                  </a>
                </p>
              </div> */}
                </div>
              </div>
              <div className="btnBox flex justify-center my-5 gap-5 ">
                <button
                  onClick={() =>
                    navigate("/signup-docs", {
                      state: location?.state,
                    })
                  }
                  className="back-btn"
                >
                  Back
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={!check}
                  className="btn"
                  style={{ background: check ? "#22C25E" : "grey" }}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Preview;
