import { toast } from "react-toastify";
import httpService from "./http.service";

export const uploadImage = async (payload) => {
  try {
    const { data } = await httpService.post("/v1/file", payload);
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};
