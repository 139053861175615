import React, { useContext, useEffect, useState } from "react";
import styles from "./agm.module.css";
import Box from "@mui/material/Box";
import {
  AgmDetals,
  createProxy,
  getUserProxy,
} from "../../services/proxy.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../component/loader/Loader";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Proxy from "../proxy/Proxy";
import { Context } from "../../App";

function Agm() {
  const [loader, setLoader] = useState(true);
  const [proxyData, setProxyData] = useState();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [agmNo, setAgmNo] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userData = useContext(Context);

  useEffect(() => {
    if (userData?.Type) {
      AgmDetals().then((res) => {
        if (res?.data) {
          setLoader(false);
          setProxyData(res?.data?.result);
        }
      });
    }
  }, [userData]);

  const noPorxy = () => {
    setLoader(true);
    const paylaod = {
      proxyNationalID: "",
      proxyName: "",
      proxyEmail: "",
      message: "",
    };
    createProxy(paylaod).then((res) => {
      if (res.data.message) {
        setLoader(false);
        toast.success(res.data.message);
      }
    });
  };

  const DateRenderer = (date) => {
    return <div>{moment(date.data.createdAt).format("DD-MM-YYYY")}</div>;
  };

  const customButton = (props) => {
    return (
      <div
        style={{
          borderRadius: 5,
          backgroundColor: "#32518F",
          width: "100%",
          color: "#fff",
          fontSize: "14px",
          fontWeight: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          if (props?.type == "Proxy Request") {
            handleOpen();
            setAgmNo(props?.data?.agmNo);
            // navigate(`/dashboard/proxy/${props?.data?.agmNo}`);
          } else if (props?.type == "Requested Proxies") {
            navigate("/dashboard/proxies");
          } else if (props?.type == "Assigned Proxies") {
            navigate("/dashboard/proxies/Assign");
          } else if (props?.type == "Incoming Proxies") {
            navigate("/dashboard/proxies/incoming");
          } else {
            let startDate = new Date(props?.data?.StartDateTime);
            startDate.setMinutes(startDate.getMinutes() - 30);

            let endDate = new Date(props?.data?.EndDateTime);
            endDate.setMinutes(endDate.getMinutes() - 30);
            // console.log(
            //   "Hit",
            //   new Date(startDate).toLocaleString(undefined, {
            //     timeZone: "Asia/Kolkata",
            //   }),
            //   new Date(endDate).toLocaleString(undefined, {
            //     timeZone: "Asia/Kolkata",
            //   }),
            //   "Now",
            //   new Date().toLocaleString(undefined, {
            //     timeZone: "Asia/Kolkata",
            //   })
            // );
            // console.log("result", startDate <= new Date());
            if (startDate <= new Date() && endDate > new Date()) {
              navigate(`/dashboard/questionarie/${props?.data?.agmNo}`);
            }
          }
        }}
      >
        {props?.type == "Proxy Request"
          ? userData?.Type == "proxy"
            ? "Request"
            : "Request / Assign"
          : "View"}
      </div>
    );
  };
  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(() => {
    setColumnDefs([
      // { headerName: "Dividend No.", field: "Dividend_Number", resizable: true },
      {
        headerName: "AGM No.",
        field: "agmNo",
        resizable: true,
      },
      {
        headerName: "Year",
        field: "Year",
        resizable: true,
      },
      { headerName: "Description", field: "Description", resizable: true },
      {
        headerName: "Proxy Request",
        resizable: true,
        cellRenderer: customButton,
        cellRendererParams: {
          type: "Proxy Request",
        },
      },
      {
        headerName: "Requested Proxy",
        resizable: true,
        cellRenderer: customButton,
        cellRendererParams: {
          type: "Requested Proxies",
        },
      },
      {
        headerName: "Assign Proxy",
        resizable: true,
        cellRenderer: customButton,
        cellRendererParams: {
          type: "Assigned Proxies",
        },
        hide: userData?.Type == "proxy",
      },
      {
        headerName: "Proxy Received Request",
        resizable: true,
        cellRenderer: customButton,
        cellRendererParams: {
          type: "Incoming Proxies",
        },
        hide: userData?.Type == "proxy",
      },
      // {
      //   headerName: "Questionnaire",
      //   resizable: true,
      //   cellRenderer: customButton,
      //   cellRendererParams: {
      //     type: "Questions",
      //   },
      // },
    ]);
  }, [userData]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    // floatingFilter: true,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <h1 className={styles.head}>AGM</h1>

          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={proxyData}
              domLayout={"autoHeight"}
              pagination={true}
              paginationPageSize={10}
              rowHeight={60}
              headerHeight={60}
            />
          </div>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Proxy handleClose={handleClose} agmNo={agmNo} />
      </Modal>
    </div>
  );
}

export default Agm;
