import React, { useCallback, useEffect, useRef, useState } from "react";
import Export from "../../utils/exports";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { getAllUser } from "../../services/proxy.service";
import Loader from "../../component/loader/Loader";
import styles from "../ShareData/share.module.css";
import { useNavigate } from "react-router-dom";

function AllUsers() {
  const [data, setData] = useState();
  const gridRef = useRef(null);
  const navigate = useNavigate();

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const DateRenderer = ({ data }) => {
    return <div>{moment(data?.createdAt).format("DD-MM-YYYY")}</div>;
  };

  const ImageRenderer = (data) => {
    return (
      <div>
        <a
          href={`${process.env.REACT_APP_UPI}/Images/${
            data?.colDef?.cellRendererParams?.type == "national_card"
              ? data?.data?.National_proof_front
              : data?.data?.Bank_proof
          }`}
          target="_blank"
          download="myimage"
        >
          {/* <p>{`${process.env.REACT_APP_UPI}/Images/${
            data?.colDef?.cellRendererParams?.type == "national_card"
              ? data?.data?.National_proof_front
                ? data?.data?.National_proof_front
                : data?.data?.National_proof
              : data?.data?.Bank_proof
          }`}</p> */}
          <img
            className={styles.docImg}
            src={`${process.env.REACT_APP_UPI}/Images/${
              data?.colDef?.cellRendererParams?.type == "national_card"
                ? data?.data?.National_proof_front
                  ? data?.data?.National_proof_front
                  : data?.data?.National_proof
                : data?.data?.Bank_proof
            }`}
          />
        </a>
      </div>
    );
  };

  const filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      cellValue = moment(cellValue).format("DD-MM-YYYY");
      const dateParts = cellValue.split("-");
      const cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );

      // Set time to midnight for comparison
      filterLocalDateAtMidnight.setHours(0, 0, 0, 0);
      cellDate.setHours(0, 0, 0, 0);

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "National Id",
      field: "NationalID",
      resizable: true,
    },
    {
      headerName: "Name",
      field: "Name",
      resizable: true,
    },
    {
      headerName: "Email",
      field: "Email",
      resizable: true,
    },
    { headerName: "Mobile", field: "Mobile", resizable: true, filter: false },
    {
      headerName: "Account Number",
      field: "Account_No",
      resizable: true,
      filter: false,
    },
    {
      headerName: "National card Attachment",
      field: "National_proof",
      resizable: true,
      cellRenderer: ImageRenderer,
      filter: false,
      cellRendererParams: {
        type: "national_card",
      },
    },
    {
      headerName: "Bank card Attachment",
      field: "Bank_proof",
      resizable: true,
      cellRenderer: ImageRenderer,
      filter: false,
      cellRendererParams: {
        type: "bank_card",
      },
    },
    {
      headerName: "Created At",
      field: "createdAt",
      resizable: true,
      cellRenderer: DateRenderer,
      filter: "agDateColumnFilter", // Ensure this is set to "agDateColumnFilter"
      filterParams: filterParams,
    },
    {
      headerName: "Status",
      field: "Status",
      resizable: true,
      cellRenderer: ({ data }) => {
        return (
          <div
            style={{
              color: data?.Status.toLowerCase() == "rejected" ? "red" : "green",
            }}
          >
            {data?.Status}
          </div>
        );
      },
    },
  ]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    // floatingFilter: true,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  useEffect(() => {
    getAllUser("shareHolder").then((res) => {
      setData(res?.result);
    });
  }, []);

  return (
    <div>
      <h1 className={styles.head}> Shareholders</h1>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Export download={onBtnExport} />
      </div>
      {data ? (
        <div id="myGrid" className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={data}
            domLayout={"autoHeight"}
            headerHeight={60}
            rowHeight={100}
            pagination={true}
            paginationPageSize={10}
            onRowClicked={(e) => {
              navigate(`/dashboard/user/${e?.data?._id}`);
            }}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default AllUsers;
