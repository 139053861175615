import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createSearchParams, useNavigate } from "react-router-dom";
import { createService } from "../../services/http.service";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import styles from "./signup.module.css";
import { FormHelperText, MenuItem } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mtdc.com.mv/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const SignUp = () => {
  const [checked, setChecked] = React.useState(false);

  const [errors, setErrors] = React.useState({
    Type: false,
    Name: false,
    NationalId: false,
    Mobile: false,
    Email: false,
    Password: false,
  });

  const handleChange = (e) => {
    // toast.success(!checked ? "Proxy" : "shareHolder")
    setChecked(e.target.value);
  };
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const payload = {
      Name: data.get("name"),
      NationalID: data.get("id"),
      Mobile: data.get("number"),
      Email: data.get("email"),
      Password: data.get("password"),
      Type: checked,
    };
    if (!validation(data)) {
      navigate("/docs", {
        state: {
          id: payload,
        },
      });
    }
    // console.log("payload", payload)
  };

  const validation = (data) => {
    let status = false;
    let errorCheck = {
      Type: false,
      Name: false,
      NationalId: false,
      Mobile: false,
      Email: false,
      Password: false,
    };
    if (!checked) {
      status = true;
      errorCheck.Type = true;
    } else if (data.get("name").length < 3) {
      status = true;
      errorCheck.Name = true;
    } else if (data.get("id").length < 5) {
      status = true;
      errorCheck.NationalId = true;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.get("email"))
    ) {
      status = true;
      errorCheck.Email = true;
    } else if (data.get("password").length < 8) {
      status = true;
      errorCheck.Password = true;
    }
    setErrors(errorCheck);
    return status;
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <Grid container component="main" sx={{ height: "100vh" }}> */}
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={styles.main}
        sx={{
          backgroundImage: "url(/images/signup_bgimage.jpeg)",
          backgroundRepeat: "no-repeat",
          width: "100%",
          // height: "100vh",
          minHeight: "100vh",
          paddingBottom: "2rem",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "2rem",
          backgroundPosition: "center",
          // gap: "1rem",
        }}
      >
        <div
          className={styles.logo}
          // style={{
          //   width: "100%",
          //   height: "100%",
          //   margin: "0.5rem auto",
          //   display:"flex",
          //   justifyContent:"center"
          // }}
        >
          <img
            src="/images/mtdc_logo.png"
            alt=""
            // style={{ width: "35%", height: "auto", }}
          />
        </div>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            margin: "0 auto",
          }}
          className={styles.registerBx}
          elevation={6}
          square
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ m: "1rem 0" }}
            className={styles.boxForm}
          >
            {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className={!checked ? styles.active : styles.nonactive} >Share Holder</p>
                  <Switch checked={checked} onChange={handleChange} />
                  <p className={checked ? styles.active : styles.nonactive}>Proxy</p>
                </div> */}
            <Typography
              className={styles.heading}
              style={{
                padding: "0.5rem 0",
                color: "#fff",
                fontSize: "19px",
                textAlign: "center",
                fontWeight: "400",
                lineHeight: "normal",
                textDecorationLine: "underline",
              }}
            >
              Register Here
            </Typography>
            <Typography
              style={{
                color: "#fff",
                fontSize: "19px",
                textAlign: "left",
              }}
            >
              Steps:
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderRadius: "50%",
                    width: "33px",
                    height: "30px",
                    border: "1px solid white",
                    backgroundColor: "#35C203",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "17px",
                      fontWeight: "500",
                      padding: "2px",
                    }}
                  >
                    1
                  </span>
                </div>
                <div
                  style={{
                    border: "1px solid white",
                    width: "100px",
                    height: "0",
                    alignItems: "center",
                    margin: "15px 0",
                  }}
                ></div>
                <div
                  style={{
                    borderRadius: "50%",
                    width: "33px",
                    height: "33px",
                    border: "1px solid white",
                    backgroundColor: "white",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "17px",
                      fontWeight: "500",
                      padding: "2px",
                      color: "black",
                    }}
                  >
                    2
                  </span>
                </div>
              </div>
            </Typography>

            <TextField
              className={styles.inputField}
              size="small"
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                color: "black",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              margin="normal"
              required
              fullWidth
              select
              variant="filled"
              id="type"
              label="Select Shareholder/proxy"
              // placeholder="Name*"
              name="type"
              autoComplete="id"
              autoFocus
              onChange={(e) => handleChange(e)} 
              error={errors.Type}
              helperText={
                errors.Type ? (
                  <FormHelperText>
                    Please select the type of user
                  </FormHelperText>
                ) : null
              }
            >
              <MenuItem value={"shareHolder"}>Share Holder</MenuItem>
              <MenuItem value={"proxy"}>Proxy</MenuItem>
            </TextField>
            <TextField
              className={styles.inputField}
              size="small"
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                color: "black",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              variant="filled"
              // placeholder="Name*"
              name="name"
              autoComplete="id"
              autoFocus
              error={errors.Name}
              helperText={
                errors.Name ? (
                  <FormHelperText>
                    Name should be atleast 3 characters
                  </FormHelperText>
                ) : null
              }
            />
            <TextField
              className={styles.inputField}
              size="small"
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="id"
              label="National ID"
              // placeholder="National ID*"
              name="id"
              autoComplete="id"
              autoFocus
              error={errors.NationalId}
              helperText={
                errors.NationalId ? (
                  <FormHelperText>
                    National Id length should be atleast 5 character
                  </FormHelperText>
                ) : null
              }
            />
            <TextField
              size="small"
              className={styles.inputField}
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="number"
              // placeholder="Phone no*"
              label="Phone no"
              type="number"
              id="number"
              autoComplete="current-password"
            />
            <TextField
              size="small"
              className={styles.inputField}
              sx={{
                bgcolor: "white",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="email"
              // placeholder="Email*"
              label="Email"
              name="email"
              type={"email"}
              autoComplete="email"
              autoFocus
              error={errors.Email}
              helperText={
                errors.Email ? (
                  <FormHelperText>email is not correct</FormHelperText>
                ) : null
              }
            />
            <TextField
              size="small"
              className={styles.inputField}
              sx={{
                bgcolor: "white",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                  fontStyle: "Poppins",
                },
              }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="password"
              // placeholder="Password*"
              label="Password"
              name="password"
              autoComplete="email"
              type="password"
              autoFocus
              error={errors.Password}
              helperText={
                errors.Password ? (
                  <FormHelperText>
                    Password should be atleast 8 characters including alphabets,
                    numeric value and special character
                  </FormHelperText>
                ) : null
              }
            />
            <FormControlLabel
              control={<Checkbox value="remember" sx={{ color: "white" }} />}
              label="Remember me"
              sx={{ color: "white", fontSize: "14px", float: "left" }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 2,
                mb: 2,
                bgcolor: "#22C25E",
                fontSize: "21px",
                fontWeight: "bold",
                borderRadius: "9px",
              }}
            >
              Next
            </Button>
            <Grid container>
              {/* <Grid item xs>
                    <Link href="#" variant="body2" style={{color:"white"}}>
                      Forgot password?
                    </Link>
                  </Grid> */}
              <Grid
                item
                className={styles.heading}
                sx={{ cursor: "pointer", margin: "0 auto" }}
                onClick={() => navigate("/")}
              >
                <Link
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "300",
                  }}
                >
                  {"Already have an account? Sign In"}
                </Link>
              </Grid>
            </Grid>
            {/* <Copyright sx={{ mt: 5, color:"white" }} /> */}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
