import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginService } from "../../services/http.service";
import styles from "./forget.module.css";
import { sendOtp } from "../../services/user.service";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mtdc.com.mv/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const CreatePass = ({ setAuthenticated }) => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      sendOtp({ email }).then((res) => {
        if (res) {
          toast.success(res?.message);
          setAuthenticated(true);
          navigate(`/otp/${res?.data?.phone_number}`, {
            state: {
              email,
              type: "forget",
            },
          });
        }
      });
    } else {
      toast.error("invalid email");
    }

    // navigate("/otp")
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <Grid container component="main" sx={{ height: "100vh" }}> */}
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={styles.main}
        sx={{
          backgroundImage: "url(/images/signup_bgimage.jpeg)",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          backgroundPosition: "center",
          gap: "1rem",
        }}
      >
        <div
          className={styles.logo}
          style={{
            width: "100%",
            height: "auto",
            margin: "0.5rem auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/mtdc_logo.png"
            alt=""
            // style={{ width: "35%", height: "auto", }}
          />
        </div>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            margin: "0 auto",
          }}
          className={styles.registerBx}
          elevation={6}
          square
        >
          <Box
            component="form"
            noValidate
            sx={{ m: "1rem 0" }}
            onSubmit={handleSubmit}
            className={styles.boxForm}
          >
            <Typography
              style={{
                padding: "1rem 0",
                color: "#fff",
                fontSize: "23px",
                textAlign: "center",
                fontWeight: "400",
                lineHeight: "normal",
                textDecorationLine: "underline",
              }}
            >
              Create New Password
            </Typography>
            <Typography
              style={{
                // padding: "12px 0",
                height: "53px",
                marginTop: "1rem",
                color: "#fff",
                fontSize: "20px",
                textAlign: "center",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              We will send you an OTP to your Mobile
            </Typography>
            <TextField
              size="small"
              className={styles.inputField}
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              required
              variant="filled"
              margin="normal"
              fullWidth
              id="email"
              // placeholder="Email*"
              label="Email"
              name="email"
              type={"email"}
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                marginTop: "2.5rem",
                marginBottom: "1.2rem",
                bgcolor: "#22C25E",
                fontSize: "21px",
                fontWeight: "bold",
                borderRadius: "9px",
              }}
            >
              Send OTP
            </Button>
            <Grid container sx={{ display: "flex", justifyContent: "end" }}>
              <Link
                variant="body2"
                sx={{ color: "white" }}
                onClick={() => navigate("/signup-personal")}
                style={{
                  color: "white",
                  fontSize: "17px",
                  fontWeight: "300",
                  cursor: "pointer",
                }}
              >
                {"Don't have account?Register here"}
              </Link>
            </Grid>
            {/* <Copyright sx={{ mt: 5, color:"white" }} /> */}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
