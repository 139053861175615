import React, { useEffect, useState } from "react";
import { FormHelperText, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { DropzoneArea } from "material-ui-dropzone";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { uploadImage } from "../../services/upload.service";
import { createService } from "../../services/http.service";
import { toast } from "react-toastify";
import Loader from "../../component/loader/Loader";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    // minHeight: 200,
    height: "5rem !important",
    width: "100%",
    border: "2px dashed #bdbdbd",
    // border : '2px solid red',
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    position: "relative",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

const UploadDocs = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const classes = useStyles();

  const initialFileState = {
    national_proof_front: location.state?.National_proof_front || "",
    national_proof_back: location.state?.National_proof_back || "",
    bank_proof: location.state?.Bank_proof || "",
  };

  const [file, setFile] = useState(initialFileState);

  const [errors, setErrors] = useState({
    national_proof_front: false,
    national_proof_back: false,
    bank_proof: false,
  });

  useEffect(() => {
    // console.log("Initial Location state:", location.state);
    // console.log("Initial file state:", initialFileState);
    setFile(initialFileState);
  }, [location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validation(file)) {
      console.log("actual submitted file", file);
      navigate("/signup-preview", {
        state: {
          ...location?.state,
          National_proof_front: file.national_proof_front,
          National_proof_back: file.national_proof_back,
          Bank_proof: file.bank_proof,
        },
      });
    }
  };

  const validation = (data) => {
    let status = false;
    let errorCheck = {
      national_proof_front: false,
      national_proof_back: false,
      bank_proof: false,
    };
    if (
      location?.state?.personalDetails?.Type === "shareHolder" &&
      !data.bank_proof
    ) {
      status = true;
      errorCheck.bank_proof = true;
    }
    if (!data?.national_proof_front) {
      status = true;
      errorCheck.national_proof_front = true;
    }
    if (!data?.national_proof_back) {
      status = true;
      errorCheck.national_proof_back = true;
    }
    setErrors(errorCheck);
    return status;
  };

  const getInitialFiles = (file) => {
    if (file && !(file instanceof File)) {
      return [{ data: { name: "Uploaded Image" }, file }];
    }
    return [];
  };

  const handleFileChange = (files, type) => {
    console.log(`Updating ${type} with`, files[0]);
    setFile((prevFile) => ({
      ...prevFile,
      [type]: files[0], // Assuming files[0] is the new file object
    }));
    setFile({ ...file, [type]: files[0] });
  };

  useEffect(() => {
    console.log("file", file);
  }, [file]);

  return (
    <div>
      <div>
        <div className="main">
          <div className="logo-box mb-4 mt-0 w-[100%] h-auto flex justify-center items-center bg-[#CEDEFE]">
            <img
              src="/images/mtdc_logo.png"
              alt="image"
              style={{ height: "auto", width: "25%" }}
            />
          </div>
          <h3 className="mainHeading mb-5">Registration</h3>
          <Typography
            style={{
              margin: "0 auto",
              color: "#fff",
              fontSize: "19px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                onClick={() =>
                  navigate("/signup-personal", {
                    state: location?.state,
                  })
                }
                style={{
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  border: "1px solid white",
                  backgroundColor: "#35C203",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "17px",
                    fontWeight: "600",
                    padding: "2px",
                  }}
                >
                  1
                </span>
              </div>
              <div
                style={{
                  border: "1px solid #333333AB",
                  width: "300px",
                  height: "0",
                  alignItems: "center",
                  margin: "15px 0",
                }}
              ></div>
              <div
                onClick={() =>
                  navigate("/signup-address", {
                    state: location?.state,
                  })
                }
                style={{
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  // border: "1px solid black",\
                  cursor: "pointer",
                  color: "#fff",
                  backgroundColor: "#35C203",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "17px",
                    fontWeight: "600",
                    padding: "2px",
                  }}
                >
                  2
                </span>
              </div>
              <div
                style={{
                  border: "1px solid #333333AB",
                  width: "300px",
                  height: "0",
                  alignItems: "center",
                  margin: "15px 0",
                }}
              ></div>
              <div
                style={{
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  border: "1px solid black",
                  backgroundColor: "#35C203",
                  color: "#fff",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "17px",
                    fontWeight: "600",
                    padding: "2px",
                  }}
                >
                  3
                </span>
              </div>
              <div
                style={{
                  border: "1px solid #333333AB",
                  width: "300px",
                  height: "0",
                  alignItems: "center",
                  margin: "15px 0",
                }}
              ></div>
              <div
                style={{
                  borderRadius: "50%",
                  width: "33px",
                  height: "33px",
                  border: "1px solid black",
                  // backgroundColor: "#35C203",
                  color: "black",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "17px",
                    fontWeight: "600",
                    padding: "2px",
                  }}
                >
                  4
                </span>
              </div>
            </div>
          </Typography>
          <div className="personalInfo">
            <h3>Upload Documents</h3>
          </div>
          <div className="borderBox">
            <Grid
              container
              mt={2}
              spacing={1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2rem",
                // gap: "10rem",
                margin: "4rem auto",
              }}
            >
              <Grid style={{ width: "18%" }}>
                <DropzoneArea
                  filesLimit={1}
                  showFileNames={true}
                  dropzoneClass={classes.dropzone}
                  dropzoneParagraphClass="myDropZoneText"
                  dropzoneText="National ID Front Card *"
                  initialFiles={
                    file.national_proof_front ? [file.national_proof_front] : []
                  }
                  acceptedFiles={[
                    "image/jpeg",
                    "image/png",
                    "image/bmp",
                    "image/gif",
                  ]}
                  onChange={(files) =>
                    handleFileChange(files, "national_proof_front")
                  }
                />
                {errors.national_proof_front && (
                  <FormHelperText style={{ color: "red" }}>
                    Please upload a National ID front.
                  </FormHelperText>
                )}
              </Grid>
              <Grid style={{ width: "18%" }}>
                <DropzoneArea
                  filesLimit={1}
                  showFileNames={true}
                  dropzoneClass={classes.dropzone}
                  dropzoneParagraphClass="myDropZoneText"
                  dropzoneText="National ID Back Card *"
                  initialFiles={getInitialFiles(file.national_proof_back)}
                  acceptedFiles={[
                    "image/jpeg",
                    "image/png",
                    "image/bmp",
                    "image/gif",
                  ]}
                  onChange={(files) =>
                    handleFileChange(files, "national_proof_back")
                  }
                />
                {errors.national_proof_back && (
                  <FormHelperText style={{ color: "red" }}>
                    Please upload a National ID back.
                  </FormHelperText>
                )}
              </Grid>
              {location?.state?.personalDetails?.Type == "shareHolder" && (
                <Grid style={{ width: "18%" }}>
                  <DropzoneArea
                    filesLimit={1}
                    showFileNames={true}
                    dropzoneClass={classes.dropzone}
                    dropzoneParagraphClass="myDropZoneText"
                    dropzoneText="Bank Proof *"
                    name="bank_proof"
                    initialFiles={getInitialFiles(file.bank_proof)}
                    acceptedFiles={[
                      "image/jpeg",
                      "image/png",
                      "image/bmp",
                      "image/gif",
                    ]}
                    onChange={(files) => handleFileChange(files, "bank_proof")}
                  />
                  {errors.bank_proof && (
                    <FormHelperText style={{ color: "red" }}>
                      Please upload a Bank proof.
                    </FormHelperText>
                  )}
                </Grid>
              )}
            </Grid>
          </div>
          <div className="btnBox flex justify-center my-5 gap-5">
            <button
              onClick={() =>
                navigate("/signup-address", {
                  state: location?.state,
                })
              }
              className="back-btn"
            >
              Back
            </button>
            {loader ? (
              <Loader />
            ) : (
              <button onClick={handleSubmit} className="btn">
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocs;
