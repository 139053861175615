import { toast } from "react-toastify";
import httpService from "./http.service";

export const getList = async () => {
  try {
    const { data } = await httpService.get("/v2/mtdc/userlist");
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const getShareData = async () => {
  try {
    const { data } = await httpService.get("/v2/mtdc/data");
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};
export const getDividend = async () => {
  try {
    const { data } = await httpService.get("/v2/mtdc/dividend");
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const LedgerDividend = async () => {
  try {
    const { data } = await httpService.get("/v2/mtdc/ledger");
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const Atolls = async () => {
  try {
    const { data } = await httpService.get("/v1/data/atoll");
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const getIslandBasedOnAtoll = async (payload) => {
  try {
    const { data } = await httpService.post("/v1/data/Island", payload);
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};
