import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { getUserProxy, userProxyTrack } from "../../services/proxy.service";
import Loader from "../../component/loader/Loader";
import styles from "../ShareData/share.module.css";
import { useParams } from "react-router-dom";
function ProxyData() {
  const [proxyData, setProxyData] = useState();
  const [loader, setLoader] = useState(false);
  const { id } = useParams();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "AGM No",
      field: "agmNo",
      resizable: true,
    },
    {
      headerName: "Proxy Name",
      field: "Name",
      resizable: true,
    },
    {
      headerName: "Proxy NationalID",
      field: "proxyNationalId",
      resizable: true,
    },
    {
      headerName: "Message",
      field: "Message",
      resizable: true,
    },
    {
      headerName: "Comment",
      field: "comment",
      resizable: true,
      cellRenderer: ({ data }) => {
        return data.status === "Reject" ? data?.comment : null;
      },
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
    },
  ]);

  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    wrapText: true,
    autoHeight: true,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  useEffect(() => {
    userProxyTrack({ Type: id }).then((res) => {
      setLoader(true);
      setProxyData(res?.data?.result);
    });
  }, []);
  return (
    <div style={{ height: "80vh" }}>
      <h1 className={styles.head}>Assigned Proxies</h1>
      {loader ? (
        <div
          id="myGrid"
          style={{
            height: "100%",
            width: "100%",
          }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={proxyData}
            pagination={true}
            paginationPageSize={10}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ProxyData;
