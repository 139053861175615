import React, { useEffect, useState } from "react";
import styles from "./details.module.css";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
import { getCurrentUser, updateCurrentUser } from "../../services/user.service";
import moment from "moment";
import { uploadImage } from "../../services/upload.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Atolls, getIslandBasedOnAtoll } from "../../services/data.service";
import Loader from "../../component/loader/Loader";
function Details() {
  const navigate = useNavigate();
  const [same, setSame] = useState(false);
  const currencies = [
    {
      value: "shareHolder",
      label: "Share Holder",
    },
    {
      value: "proxy",
      label: "Proxy",
    },
  ];
  const [loader, setLoader] = useState(true);

  const [atolls, setAtolls] = useState();
  const [Islands, setIslands] = useState({
    permanent: [],
    current: [],
  });
  const [details, setDetails] = useState({
    Account_No: "",
    DOB: "",
    Email: "",
    Mobile: "",
    Name: "",
    NationalID: "",
    Type: "",
    currentStreet: "",
    currentAtoll: "",
    currentIsland: "",
    currentHouseNo: "",
    currentZip: "",
    currentCity: "",
    currentCountry: "",
    Minor: "",
    permanentStreet: "",
    permanentAtoll: "",
    permanentIsland: "",
    permanentHouseNo: "",
    permanentZip: "",
    permanentCity: "",
    permanentCountry: "",
    Parent_name: "",
    Parent_NationalID: "",
    Relation: "",
    Contact: "",
    Bank_proof: "",
    National_proof_back: "",
    National_proof_front: "",
    Comment: "",
  });

  const [files, setFiles] = useState({
    Bank_proof: "",
    National_proof_back: "",
    National_proof_front: "",
  });

  useEffect(() => {
    getCurrentUser().then((res) => {
      if (res?.result) {
        const {
          Account_No,
          DOB,
          Email,
          Mobile,
          Name,
          NationalID,
          Type,
          Current_address,
          Permanent_address,
          Minor,
          Bank_proof,
          National_proof_back,
          National_proof_front,
          Comment,
          Guardian_info,
        } = res?.result;

        setDetails({
          ...details,
          Account_No: JSON.stringify(Account_No),
          DOB,
          Email,
          Mobile,
          Name,
          NationalID,
          Type,
          Minor,
          Parent_name: Guardian_info?.Parent_name,
          Parent_NationalID: Guardian_info?.Parent_NationalID,
          Relation: Guardian_info?.Relation,
          Contact: JSON.stringify(Guardian_info?.Contact),
          Bank_proof,
          National_proof_back,
          National_proof_front,
          Comment,
          currentStreet: Current_address?.Street,
          currentAtoll: Current_address?.Atoll,
          currentIsland: Current_address?.Island,
          currentHouseNo: Current_address?.HouseNo,
          currentZip: Current_address?.Zip,
          currentCity: Current_address?.City,
          currentCountry: Current_address?.Country,
          permanentStreet: Permanent_address?.Street,
          permanentAtoll: Permanent_address?.Atoll,
          permanentIsland: Permanent_address?.Island,
          permanentHouseNo: Permanent_address?.HouseNo,
          permanentZip: Permanent_address?.Zip,
          permanentCity: Permanent_address?.City,
          permanentCountry: Permanent_address?.Country,
        });
        getIslandBasedOnAtoll({
          atollCode: Current_address?.Atoll,
        }).then((abs) => {
          setIslands({ ...Islands, current: abs?.result });
        });
        getIslandBasedOnAtoll({
          atollCode: Permanent_address?.Atoll,
        }).then((abs) => {
          setIslands({ ...Islands, permanent: abs?.result });
        });
        setLoader(false);
      }
    });
    Atolls().then((res) => {
      if (res?.result) {
        setAtolls(res?.result);
      }
    });
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e?.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };
  const handleChangeFiles = (e) => {
    const { name } = e.target;
    const selectedFiles = e.target.files;

    // Make sure files array is not empty before trying to access it
    if (selectedFiles.length > 0) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [name]: selectedFiles[0],
      }));
    }
  };

  const handleUpdate = async () => {
    const formData1 = new FormData();
    const formData2 = new FormData();
    const formData3 = new FormData();
    formData1.append("file", files?.National_proof_front);
    formData2.append("file", files?.National_proof_back);
    formData3.append("file", files?.Bank_proof);
    try {
      const [res1, res2, res3] = await Promise.all([
        uploadImage(formData1),
        uploadImage(formData2),
        uploadImage(formData3),
      ]);

      const updatedDetails = {};

      if (res1?.result) {
        console.log(1, res1?.result);
        updatedDetails.National_proof_front = res1?.result;
      }

      if (res2?.result) {
        console.log(2, res2?.result);
        updatedDetails.National_proof_back = res2?.result;
      }

      if (res3?.result) {
        console.log(3, res3?.result);
        updatedDetails.Bank_proof = res3?.result;
      }

      setDetails((prevDetails) => {
        const updatedData = {
          ...prevDetails,
          ...updatedDetails,
        };
        const payload = {
          ...updatedData,
          Account_No: updatedData?.Account_No,
          Permanent_address: {
            Street: updatedData?.permanentStreet,
            Atoll: updatedData?.permanentAtoll,
            Island: updatedData?.permanentIsland,
            HouseNo: updatedData?.permanentHouseNo,
            Zip: updatedData?.permanentZip,
            City: updatedData?.permanentCity,
            Country: updatedData?.permanentCountry,
          },
          Current_address: {
            Street: updatedData?.currentStreet,
            Atoll: updatedData?.currentAtoll,
            Island: updatedData?.currentIsland,
            HouseNo: updatedData?.currentHouseNo,
            Zip: updatedData?.currentZip,
            City: updatedData?.currentCity,
            Country: updatedData?.currentCountry,
          },
          Guardian_info: {
            Parent_name: updatedData?.Parent_name,
            Parent_NationalID: updatedData?.Parent_NationalID,
            Relation: updatedData?.Relation,
            Contact: updatedData?.Contact!=="null" ? updatedData?.Contact : 0,
          },
        };
        
        updateCurrentUser(payload).then((res) => {
          toast.success(res?.message);
        })
        return updatedData;
      });
    } catch (error) {
      console.error("Error uploading files", error);
    }
  };

  useEffect(() => {
    if (same == true) {
      setDetails({
        ...details,
        currentStreet: details?.permanentStreet,
        currentAtoll: details?.permanentAtoll,
        currentIsland: details?.permanentIsland,
        currentHouseNo: details?.permanentHouseNo,
        currentZip: details?.permanentZip,
        currentCity: details?.permanentCity,
        currentCountry: details?.permanentCountry,
      });
    }
  }, [same]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className={styles.container}>
          <div className={styles.main}>
            <h5>Registeration Update</h5>
            <div className={styles.commentBox}>
              <p className={styles.head}>Comment: </p>
              <p className={styles.comment}>
                {details?.Comment?.toUpperCase()}
              </p>
            </div>
            <div className={styles.status}>
              <p>
                Status :{" "}
                <span style={{ fontSize: "14px", color: "#F9BE24" }}>
                  In Progress
                </span>
              </p>
              <div>
                <Button
                  variant="contained"
                  sx={{
                    background: "#22C25E",
                  }}
                  onClick={handleUpdate}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    background: "#22C25E",
                  }}
                  onClick={() => {
                    localStorage.removeItem("token");
                    navigate("/login");
                  }}
                >
                  Log Out
                </Button>
              </div>
            </div>
            {details && (
              <div className={styles.profileDetails}>
                <div className={styles.personalInfo}>
                  <h3>Personal Details</h3>
                </div>
                <div className={styles.infoContainer}>
                  <div>
                    <label htmlFor="">Select Type</label>
                    <TextField
                      id="outlined-basic"
                      select
                      value={details?.Type}
                      name="Type"
                      disabled
                      // onChange={handleChange}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <label htmlFor="">Name</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.Name}
                      name="Name"
                      onChange={handleChange}
                    />
                    <label htmlFor="">DOB</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          "DatePicker",
                          "MobileDatePicker",
                          "DesktopDatePicker",
                          "StaticDatePicker",
                        ]}
                      >
                        <DatePicker
                          name="DOB"
                          onChange={(dateObject) => {
                            // Assuming dateObject.$d is the Date object
                            const formattedDate = `${dateObject.$D}-${(
                              dateObject.$M + 1
                            )
                              .toString()
                              .padStart(2, "0")}-${dateObject.$y}`;

                            if (
                              new Date().getFullYear() - dateObject?.$y <=
                              18
                            ) {
                              setDetails({
                                ...details,
                                Minor: true,
                                DOB: formattedDate,
                              });
                            } else {
                              setDetails({
                                ...details,
                                Minor: false,
                                DOB: formattedDate,
                              });
                            }
                          }}
                          // format="DD-MM-YYYY"
                          value={dayjs(
                            moment(details?.DOB).format("YYYY-MM-DD")
                          )}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {details?.Minor && (
                      <>
                        <label htmlFor="">Name of Guardians</label>
                        <TextField
                          id="outlined-select-currency"
                          value={details?.Parent_name}
                          name="Parent_name"
                          onChange={handleChange}
                        />
                        <label htmlFor="">Relation</label>
                        <TextField
                          id="outlined-select-currency"
                          value={details?.Relation}
                          name="Relation"
                          onChange={handleChange}
                        />
                      </>
                    )}
                    <label htmlFor="">Email</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.Email}
                      name="Email"
                      disabled
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="">National ID</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.NationalID}
                      name="NationalID"
                      // onChange={handleChange}
                    />
                    <label htmlFor="">Contact No.</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.Mobile}
                      name="Mobile"
                      onChange={handleChange}
                    />
                    <label htmlFor="">Account No.</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.Account_No}
                      name="Account_No"
                      onChange={handleChange}
                    />

                    {details?.Minor && (
                      <>
                        <label htmlFor="">Guardian's ID</label>
                        <TextField
                          id="outlined-select-currency"
                          value={details?.Parent_NationalID}
                          name="Parent_NationalID"
                          onChange={handleChange}
                        />
                        <label htmlFor="">Guardian's Contact</label>
                        <TextField
                          id="outlined-select-currency"
                          value={details?.Contact}
                          name="Contact"
                          onChange={handleChange}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.personalInfo}>
                  <h3>Address Details</h3>
                </div>
                <h3 className="m-4">Permanent Address</h3>
                <div className={styles.infoContainer}>
                  <div>
                    <label htmlFor="">Select Atoll</label>
                    <TextField
                      id="outlined-basic"
                      select
                      value={details?.permanentAtoll}
                      name="permanentAtoll"
                      onChange={handleChange}
                    >
                      {atolls?.map((el) => {
                        return (
                          <MenuItem
                            value={el?.atollName}
                            onClick={() => {
                              getIslandBasedOnAtoll({
                                atollCode: el?.atollCode,
                              }).then((res) => {
                                setIslands({
                                  ...Islands,
                                  permanent: res?.result,
                                });
                              });
                            }}
                          >
                            {el?.atollName}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <label htmlFor="">House No.</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.permanentHouseNo}
                      name="permanentHouseNo"
                      onChange={handleChange}
                    />
                    <label htmlFor="">City</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.permanentCity}
                      name="permanentCity"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="">Select Island</label>
                    <TextField
                      id="outlined-basic"
                      select
                      value={details?.permanentIsland}
                      name="permanentIsland"
                      onChange={handleChange}
                    >
                      {Islands?.permanent?.map((el) => {
                        return (
                          <MenuItem value={el?.islandName}>
                            {el?.islandName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <label htmlFor="">Street Name</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.permanentStreet}
                      name="permanentStreet"
                      onChange={handleChange}
                    />
                    {/* <label htmlFor="">State/Province</label>
                <TextField id="outlined-select-currency" value={details?.perma} /> */}
                    <label htmlFor="">Postal/ZIP Code</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.permanentZip}
                      name="permanentZip"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={styles.checkbox}>
                  <Checkbox onChange={() => setSame(!same)} />
                  <p>Same as permanent address</p>
                </div>
                <h3 className="m-4">Current Address</h3>
                <div className={styles.infoContainer}>
                  <div>
                    <label htmlFor="">Select Atoll</label>
                    <TextField
                      id="outlined-basic"
                      select
                      value={details?.currentAtoll}
                      name="currentAtoll"
                      onChange={handleChange}
                    >
                      {atolls?.map((el) => {
                        return (
                          <MenuItem
                            value={el?.atollName}
                            onClick={() => {
                              getIslandBasedOnAtoll({
                                atollCode: el?.atollCode,
                              }).then((res) => {
                                setIslands({
                                  ...Islands,
                                  current: res?.result,
                                });
                              });
                            }}
                          >
                            {el?.atollName}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <label htmlFor="">House No.</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.currentHouseNo}
                      name="currentHouseNo"
                      onChange={handleChange}
                    />
                    <label htmlFor="">City</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.currentCity}
                      name="currentCity"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="">Select Island</label>
                    <TextField
                      id="outlined-basic"
                      select
                      value={details?.currentIsland}
                      name="currentIsland"
                      onChange={handleChange}
                    >
                      {Islands?.current?.map((el) => {
                        return (
                          <MenuItem value={el?.islandName}>
                            {el?.islandName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <label htmlFor="">Street Name </label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.currentStreet}
                      name="currentStreet"
                      onChange={handleChange}
                    />
                    {/* <label htmlFor="">State/Province</label>
                <TextField id="outlined-select-currency" value={details?.perma} /> */}
                    <label htmlFor="">Postal/ZIP Code</label>
                    <TextField
                      id="outlined-select-currency"
                      value={details?.currentZip}
                      name="currentZip"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={styles.personalInfo}>
                  <h3>Upload Document</h3>
                </div>
                <div className={styles.docs}>
                  <div>
                    <p>Front copy of National ID Card</p>
                    <img
                      src={
                        files?.National_proof_front
                          ? URL.createObjectURL(files?.National_proof_front)
                          : `${process.env.REACT_APP_UPI}/Images/${details.National_proof_front}`
                      }
                      alt=""
                    />
                    <div className={styles.upload}>
                      <input
                        type="file"
                        name="National_proof_front"
                        onChange={handleChangeFiles}
                      />
                    </div>
                  </div>
                  <div>
                    <p>Back copy of National ID Card</p>
                    <img
                      src={
                        files?.National_proof_back
                          ? URL.createObjectURL(files?.National_proof_back)
                          : `${process.env.REACT_APP_UPI}/Images/${details.National_proof_back}`
                      }
                      alt=""
                    />
                    <div className={styles.upload}>
                      <input
                        type="file"
                        name="National_proof_back"
                        onChange={handleChangeFiles}
                      />
                    </div>
                  </div>
                  {details?.Type == "shareHolder" && (
                    <div>
                      <p>Account Detail Slip</p>
                      <img
                        src={
                          files?.Bank_proof
                            ? URL.createObjectURL(files?.Bank_proof)
                            : `${process.env.REACT_APP_UPI}/Images/${details.Bank_proof}`
                        }
                        alt=""
                      />
                      <div className={styles.upload}>
                        <input
                          type="file"
                          name="Bank_proof"
                          onChange={handleChangeFiles}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Details;
