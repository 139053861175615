import React, { useCallback, useRef, useState } from "react";
import styles from "../ShareData/share.module.css";
import Export from "../../utils/exports";
import { useNavigate } from "react-router-dom";
import style from "./admin.module.css";
import { Button } from "@mui/material";
import { createAdmin } from "../../services/user.service";
import { toast } from "react-toastify";
function CreateAdmin() {
  const gridRef = useRef(null);
  const navigate = useNavigate();

  const [admin, setAdmin] = useState({
    Name: "",
    Email: "",
    Password: "",
    Mobile: "",
    // Account_No: "",
    DOB: "",
  });

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdmin({
      ...admin,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("admin", admin);
    createAdmin(admin).then((res) => {
      toast.success(res?.message);
      setAdmin({
        Name: "",
        Email: "",
        Password: "",
        Mobile: "",
        // Account_No: "",
        DOB: "",
      });
      navigate("/dashboard/new-admin")
    });
  };

  return (
    <div>
      <h1 className={styles.head}>Create New Admin</h1>
      <div className={style.adminForm}>
        <h5>Create New Admin</h5>
        <form action="" onSubmit={handleSubmit}>
          <label htmlFor="">
            Name <span>*</span>
          </label>
          <input type="text" name="Name" value={admin?.Name} onChange={handleChange} />
          {/* <label htmlFor="">
            National ID <span>*</span>
          </label>
          <input type="text" name="NationalID" onChange={handleChange} /> */}
          <label htmlFor="">
            Contact No. <span>*</span>
          </label>
          <input type="number" name="Mobile" value={admin?.Mobile} onChange={handleChange} />
          <label htmlFor="">
            DOB <span>*</span>
          </label>
          <input type="date" name="DOB"  onChange={handleChange} />
          <label htmlFor="">
            Email <span>*</span>
          </label>
          <input type="email" name="Email" value={admin?.Email} onChange={handleChange} />
          <label htmlFor="">
            Password <span>*</span>
          </label>
          <input type="text" name="Password" value={admin?.Password} onChange={handleChange} />
          {/* <label htmlFor="">
            Account No. <span>*</span>
          </label>
          <input type="number" name="Account_No" onChange={handleChange} /> */}
          <label htmlFor=""></label>
          <button type="btn" className="btn">
            Create
          </button>
        </form>
      </div>
    </div>
  );
}
export default CreateAdmin;
