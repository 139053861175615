import { toast } from "react-toastify";
import httpService from "./http.service";
import { Try } from "@mui/icons-material";

export const getCurrentUser = async () => {
  try {
    const { data } = await httpService.get("/v2/user/me");
    return data;
  } catch (err) {
    // toast.error(err.message);
  }
};

export const updateCurrentUser = async (payload) => {
  try {
    const { data } = await httpService.put("/v2/user/update", payload);
    return data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
};
export const updateProfileImage = async (payload) => {
  try {
    const { data } = await httpService.put("/v2/user/update-image", payload);
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};
export const getSingleUser = async (payload) => {
  try {
    const { data } = await httpService.post("/v2/user/single-user", payload);
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const createAdmin = async (payload) => {
  try {
    const { data } = await httpService.post("/v2/user/create-admin", payload);
    return data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
};

export const sendOtp = async (payload) => {
  try {
    const { data } = await httpService.post("/v1/user/otp", payload);
    return data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const verifyOtp = async (payload) => {
  try {
    const { data } = await httpService.post("/v1/user/verify-otp", payload);
    return data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
};

export const changePassword = async (payload) => {
  try {
    const { data } = await httpService.post(
      "/v1/user/change-password",
      payload
    );
    return data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
};

export const loginViaOTP = async (payload) => {
  try {
    const data = await httpService.post("/v1/user/login-viaOTP", payload);
    return data;
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
};

export const checkShareHolderExistatnce = async(payload)=>{
  try {
    const data = await httpService.post("/v1/user/check", payload)
    return data
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
}