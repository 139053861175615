import { useContext, useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// mocks_
import account from "../../../_mock/account";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../App";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./index.module.css";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    icon: "eva:person-fill",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const userData = useContext(Context);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleProfile = () => {
    setOpen(null);
    navigate("/dashboard/my-profile");
  };
  const handlelogOut = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={
            userData?.Image
              ? `${process.env.REACT_APP_UPI}/Images/${userData?.Image}`
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
          }
          alt="photoURL"
        />
        <ArrowDropDownIcon fontSize="medium" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: {
              md: "40%",
              sm: "40%",
              xs: "80%",
              lg: "20%",
              // lg : "80%"
            },
            height: "20rem",
            display: "flex",
            flexDirection: "column",
            padding: 2,
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
            border: "1px solid var(--B6A7A7, #B6A7A7)",
            alignItems: "center",
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#000",
            fontSize: "16px",
            fontWeight: 400,
            display: "flex",
            // width: "70%",
            justifyContent: "space-between",
            // marginLeft: "30%",
            alignItems: "flex-end",
            // margin : "auto",
            width: "100%",
            alignItems: "center",
          }}
          noWrap
        >
          <p style={{ textAlign: "center", width: "90%" }}>{userData?.Email}</p>
          <CloseIcon
            sx={{ cursor: "pointer", margin: "0 0 10px 0" }}
            onClick={handleClose}
          />
        </Typography>
        <Avatar
          src={
            userData?.Image
              ? `${process.env.REACT_APP_UPI}/Images/${userData?.Image}`
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
          }
          sx={{ height: "81px", width: "81px", mt: 2 }}
          alt="photoURL"
        />
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle2"
            noWrap
            sx={{
              color: "#000",
              fontFamily: "sans-serif",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            Hi, {userData?.Name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleProfile}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <div
          style={{
            width: "70%",
            height: "33px",
            borderRadius: "16px",
            border: "1px solid var(--B6A7A7, #B6A7A7)",
            background: "#FFF",
            color: "#32518F",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "13px",
            marginTop: "17px",
            cursor: "pointer",
          }}
          onClick={handleProfile}
        >
          Manage your account
        </div>

        <Divider sx={{ borderStyle: "dashed" }} />

        <div
          style={{
            marginTop: "17px",
            width: "50%",
            height: "28px",
            borderRadius: "16px",
            backgroundColor: "#D9D9D9",
            border: "1px solid var(--B6A7A7, #B6A7A7)",
            color: "#32518F ",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "15px",
            fontWeight: 500,
            cursor: "pointer",
            border: "none",
          }}
          onClick={handlelogOut}
          // sx={{ m: 1 }}
        >
          Logout
        </div>
      </Popover>
    </>
  );
}
