import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import { SignIn } from "./pages/signIn/SignIn";
import { SignUp } from "./pages/signUp/SignUp";
import { PageNotFound } from "./pages/pageNotFound";
import Loader from "./component/loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import { Navigate, Route, Routes } from "react-router-dom";
import NonLoginGuard from "./guards/NonLoggedGuard/NonLoggedGaurd";
import LoginGuard from "./guards/LoginGuard/LoginGuard";
import PublicRoute from "./routes/publicRoute";
import PrivateRoute from "./routes/privateRoute";
import { getCurrentUser } from "./services/user.service";
import Details from "./pages/profile/Details";

export const Context = React.createContext({});
function App() {
  const [mine, setMine] = useState();

  // const getting = useCallback(() => {
  //   getCurrentUser().then(({ result }) => {
  //     return result;
  //   });
  // }, []);

  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    getCurrentUser().then((res) => {
      setUserDetails(res?.result);
    });
  }, [localStorage.getItem("token")]);

  return (
    <div className="body">
      <Context.Provider value={userDetails}>
        <ToastContainer />
        <Routes>
          <Route element={<NonLoginGuard />}>
            <Route path="/*" element={<PublicRoute />} />
          </Route>
          <Route element={<LoginGuard />}>
            <Route path="dashboard/*" element={<PrivateRoute />} />
          </Route>
          <Route path="/update-details" element={<Details />} />
          <Route path="" element={<Navigate to="/login" />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Context.Provider>
    </div>
  );
}

export default App;
