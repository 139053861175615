import React, { useEffect, useState } from "react";

function Timer({ proxyData, setVisible }) {
  const [timeDifference, setTimeDifference] = useState(0);
  useEffect(() => {
    const currentDate = new Date();
    const specificDate = new Date(proxyData?.EndDateTime);
    specificDate.setMinutes(specificDate?.getMinutes()-30)
    if (currentDate >= specificDate) {
      setVisible(false);
      return;
    }

    const newTimeDifference = specificDate - currentDate;

    setTimeDifference(newTimeDifference);

    const intervalId = setInterval(() => {
      const updatedTimeDifference = specificDate - new Date();
      setTimeDifference(updatedTimeDifference);
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  // Convert milliseconds to seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  return (
    <div>
      Time Remaining :
      <span>
        {hours}:{minutes}:{seconds}
      </span>
    </div>
  );
}

export default Timer;
