import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loginService } from "../../services/http.service";
import styles from "./forget.module.css";
import { loginViaOTP, verifyOtp } from "../../services/user.service";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mtdc.com.mv/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const OTP = ({ setAuthenticated }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState();
  const { mob } = useParams();
  const location = useLocation();
  const { email, type } = location?.state;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (otp?.length) {
      if (type == "login") {
        console.log("login hit");
        loginViaOTP({
          email: email,
          code: otp,
          phoneNumber: mob,
        }).then((res) => {
          if (res?.data?.status == "OK") {
            localStorage.setItem("token", res?.data?.accessToken);
            if (res?.data?.result?.Type == "proxy") {
              // console.log("res", res?.data?.result?.Status)
              if (res?.data?.result?.Status == "Inprogress") {
                console.log(1);
                navigate("/update-details");
              } else {
                console.log(2);
                navigate("/dashboard/agm");
              }
            } else if (res?.data?.result?.Type == "shareHolder") {
              if (res?.data?.result?.Status == "Inprogress") {
                navigate("/update-details");
              } else {
                navigate("/dashboard/app");
              }
            } else {
              navigate("/dashboard/all-shareHolders");
            }
            window.location.reload();
          }
        });
      } else {
        verifyOtp({ phoneNumber: mob, code: otp }).then((res) => {
          if (res) {
            toast.success(res?.message);
            setAuthenticated(true);
            navigate(`/setpass/${mob}`, {
              state: {
                email: email,
                otp: otp,
              },
            });
          }
        });
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <Grid container component="main" sx={{ height: "100vh" }}> */}
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className={styles.main}
        sx={{
          backgroundImage: "url(/images/signup_bgimage.jpeg)",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "100vh",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          backgroundPosition: "center",
          gap: "1rem",
        }}
      >
        <div
          className={styles.logo}
          style={{
            width: "100%",
            height: "auto",
            margin: "0.5rem auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src="/images/mtdc_logo.png"
            alt=""
            // style={{ width: "35%", height: "auto", }}
          />
        </div>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            margin: "0 auto",
            // height: "40%",
          }}
          className={styles.registerBx}
          elevation={6}
          square
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ m: "1rem 0" }}
            className={styles.boxForm}
          >
            {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className={!checked ? styles.active : styles.nonactive} >Share Holder</p>
                <Switch checked={checked} onChange={handleChange} />
                <p className={checked ? styles.active : styles.nonactive}>Proxy</p>
              </div> */}
            <Typography
              style={{
                padding: "2rem 0",
                color: "#fff",
                fontSize: "23px",
                textAlign: "center",
                fontWeight: "400",
                lineHeight: "normal",
                textDecorationLine: "underline",
              }}
            >
              Create New Password
            </Typography>
            <Typography
              style={{
                padding: "0",
                color: "#fff",
                fontSize: "13px",
                textAlign: "center",
                fontWeight: "400",
                lineHeight: "normal"
              }}
            >
              We have sent an OTP to your registered mobile number {mob}
            </Typography>

            <TextField
              size="small"
              className={styles.inputField}
              sx={{
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                  fontWeight: "500",
                  fontSize: "13px",
                },
              }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="id"
              label="Enter OTP"
              // placeholder="National ID*"
              name="id"
              autoComplete="id"
              autoFocus
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              // onClick={() => navigate("/setpass")}
              sx={{
                marginTop: "2.5rem",
                marginBottom: "1.2rem",
                bgcolor: "#22C25E",
                fontSize: "21px",
                fontWeight: "bold",
                borderRadius: "9px",
              }}
            >
              Submit
            </Button>
            <Grid container sx={{ display: "flex", justifyContent: "end" }}>
              {/* <Grid item xs>
                  <Link href="#" variant="body2" style={{color:"white"}}>
                    Forgot password?
                  </Link>
                </Grid> */}
              <Link
                variant="body2"
                sx={{ color: "white" }}
                onClick={() => navigate("/signup-personal")}
                style={{
                  color: "white",
                  fontSize: "17px",
                  fontWeight: "300",
                  cursor: "pointer",
                }}
              >
                {"Don't have account?Register here"}
              </Link>
            </Grid>
            {/* <Copyright sx={{ mt: 5, color:"white" }} /> */}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
