import { toast } from "react-toastify";
import httpService from "./http.service";

export const addAnswer = async (payload) => {
  try {
    const data = await httpService.post("/v2/question-answers/add", payload);
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};
