import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// mock
import account from "../../../_mock/account";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../component/logo";
import Scrollbar from "../../../component/scrollbar";
import NavSection from "../../../component/nav-section/index";
import { navAdmin, navConfig, navProxy } from "./config";
import { Context } from "../../../App";

// ----------------------------------------------------------------------

const NAV_WIDTH = "22%";
const Mob_Width = 344;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userData = useContext(Context);
  // const value = useContext();
  // console.log("checking in this", value);
  const isDesktop = useResponsive("up", "lg");
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo />
      </Box> */}

      <Box sx={{ mx: 1 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar
              src={
                userData?.Image
                  ? `${process.env.REACT_APP_UPI}/Images/${userData?.Image}`
                  : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
              }
              alt="photoURL"
              sx={{ height: "72px", width: "73px" }}
            />

            <Box>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "#FFF",
                  fontWeight: "400",
                  fontSize: "18px",

                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "5px",
                  mt: 1,
                  // border: "2px solid red",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/images/hey.png"
                  style={{ width: "34px", height: "34px" }}
                />
                <p style={{ fontFamily: "Poppins", fontSize : "18px" }}>Hi , </p>
                <span
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "-0.24px",
                    fontSize: "20px",
                    display: "flex",
                    // width : "80%",
                    // border : "2px solid red"
                  }}
                >
                  {userData?.Name}
                </span>
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <div style={{ height: "1px", background: "#B6A7A7" }}></div>

      {userData?.Name && userData?.Type == "shareHolder" && (
        <NavSection data={navConfig} />
      )}
      {userData?.Name && userData?.Type == "proxy" && (
        <NavSection data={navProxy} />
      )}
      {userData?.Name && userData?.Type == "admin" && (
        <NavSection data={navAdmin} />
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "#32518F",
              borderRightStyle: "dashed",
              marginTop: "5rem",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: Mob_Width, bgcolor: "#32518F" },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
