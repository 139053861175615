import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import styles from "./share.module.css";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import { getShareData } from "../../services/data.service";
import moment from "moment/moment";
import Loader from "../../component/loader/Loader";

function ShareData() {
  const [data, setData] = useState();

  const DateRenderer = (date) => {
    return <div>{moment(date?.data?.Date).format("DD-MM-YYYY")}</div>;
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Register No.",
      field: "RegNo",
      resizable: true,
      
    },
    {
      headerName: "Certificate No.",
      field: "CertificateNo",
      resizable: true,
      
    },
    {
      headerName: "Share Holder Type",
      field: "ShareholderType",
      resizable: true,
      
    },
    {
      headerName: "Share Holder No.",
      field: "ShareholderNo",
      resizable: true,
      
    },
    {
      headerName: "Share Holder Name",
      field: "ShareholderName",
      resizable: true,
      
    },
    {
      headerName: "Date",
      field: "Date",
      resizable: true,
      cellRenderer: DateRenderer,
      
    },
    {
      headerName: "Entry Type",
      field: "EntryType",
      resizable: true,
      
    },
    { headerName: "Shares", field: "Shares", resizable: true },
  ]);
  const defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    // floatingFilter: true,
    // minWidth : 50,
    minWidth: 150,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };

  useEffect(() => {
    getShareData().then((res) => {
      if (res?.status == "OK") {
        setData(res?.result);
      }
    });
  }, []);
  // console.log("data", data);
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <h1 className={styles.head}>Share Register Entities</h1>
      {data ? (
        <div
          id="myGrid"
          style={{
            height: "70vh",
            width: "100%",
          }}
          className="ag-theme-alpine"
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={data}
            headerHeight={50}
            rowHeight={40}
            pagination={true}
            paginationPageSize={10}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default ShareData;
