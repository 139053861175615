import { toast } from "react-toastify";
import httpService from "./http.service";

export const setDateAndTime = async (payload) => {
  try {
    const data = await httpService.post("/v2/user/set-up", payload);
    return data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getSetUpDateAndTime = async () => {
  try {
    const data = await httpService.get("/v1/user/setup");
    return data;
  } catch (error) {
    toast.error(error.message);
  }
};
