import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../component/iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import useResponsive from "../../../hooks/useResponsive";
import Logo from "../../../component/logo";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = "5rem";

const StyledRoot = styled(AppBar)(({ theme }) => ({
  // ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  background : "white",
  [theme.breakpoints.up("lg")]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5, 0, 2),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const lgUp = useResponsive("up", "lg");
  return (
    <StyledRoot>
      <StyledToolbar>
        {!lgUp && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}

        <Box
          sx={{
            display: "inline-flex",
            width: "22%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="/images/dashboard_logo.png"
            style={{ width: "81px", height: "81px" }}
          />
          <div style={{ color: "#32518F", fontFamily: "sans-serif"}}>
            <h1 style={{ fontSize: "30px" ,fontWeight : "700", margin : "0px"}}>MTDC</h1>
            <p style={{ fontSize: "10px", margin: "0px" , padding : "0px"}}>
              MALDIVES TOURISM DEVELOPMENT CORPORATION
            </p>
          </div>
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
