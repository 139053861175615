import React, { useEffect, useState } from "react";
import "./personaldetails.css";
import { FormHelperText, MenuItem, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import { getSetUpDateAndTime } from "../../services/setUp.service";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Coffee, Visibility, VisibilityOff } from "@mui/icons-material";
import { checkShareHolderExistatnce } from "../../services/user.service";

const PersonalDetails = () => {
  const navigate = useNavigate();
  const [minor, setMinor] = useState(false);
  const [count, setCount] = useState(1);
  const [show, setShow] = useState(false);
  const [showPass, setShowPass] = useState({
    password: false,
    confirm: false,
  });

  const currencies = [
    {
      value: "shareHolder",
      label: "Share Holder",
    },
    {
      value: "proxy",
      label: "Proxy",
    },
  ];

  const location = useLocation();

  const [personalDetails, setPersonalDetails] = useState({
    Name: "",
    NationalID: "",
    Mobile: "",
    Email: "",
    Type: "shareHolder",
    DOB: "",
    Password: "",
    Confirm: "",
    Parent_name: "",
    Parent_NationalID: "",
    Relation: "",
    Contact: "",
    Account_No: "",
    Minor: minor,
  });

  const [errors, setErrors] = useState({
    Name: false,
    NationalID: false,
    Mobile: false,
    Email: false,
    Type: false,
    DOB: false,
    Password: false,
    Confirm: false,
    Parent_name: false,
    Parent_NationalID: false,
    Relation: false,
    Contact: false,
    Account_No: false,
  });

  const handleChange = async (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    await setPersonalDetails({
      ...personalDetails,
      [name]: value,
    });
    if (count > 1) {
      validation({ ...personalDetails, [name]: value });
    }
  };

  const validation = (data) => {
    let status = false;
    let errorCheck = {
      Type: false,
      Name: false,
      NationalID: false,
      Mobile: false,
      Email: false,
      Password: false,
      DOB: false,
      Parent_name: false,
      Parent_NationalID: false,
      Relation: false,
      // Contact: false,
      Confirm: false,
      Account_No: false,
    };
    if (!data?.Type) {
      status = true;
      errorCheck.Type = true;
    }
    if (data?.NationalID?.length < 5) {
      status = true;
      errorCheck.NationalID = true;
    }
    if (data?.Name?.length < 3) {
      status = true;
      errorCheck.Name = true;
    }
    if (!data?.Mobile?.match("^[0-9]{10}$")) {
      status = true;
      errorCheck.Mobile = true;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data?.Email)) {
      status = true;
      errorCheck.Email = true;
    }
    if (data?.Password?.length < 8) {
      status = true;
      errorCheck.Password = true;
    }
    if (!data?.DOB) {
      status = true;
      errorCheck.DOB = true;
    }
    if (minor && data?.Parent_name?.length < 3) {
      status = true;
      errorCheck.Parent_name = true;
    }
    if (minor && data?.Parent_NationalID?.length < 5) {
      status = true;
      errorCheck.Parent_NationalID = true;
    }
    if (minor && !data?.Relation) {
      status = true;

      errorCheck.Relation = true;
    }
    if (data?.Type == "shareHolder" && data?.Account_No?.length < 13) {
      status = true;
      errorCheck.Account_No = true;
    }
    if (!data.Confirm) {
      status = true;
      errorCheck.Confirm = true;
    }
    if (data?.Password !== data?.Confirm) {
      status = true;
      errorCheck.Confirm = true;
    }
    setErrors(errorCheck);
    return status;
  };

  const handleSubmit = () => {
    setCount((prev) => {
      return prev + 1;
    });
    if (!validation(personalDetails)) {
      if (personalDetails.Type == "shareHolder") {
        checkShareHolderExistatnce(personalDetails).then((res) => {
          if (res?.data) {
            navigate("/signup-address", {
              state: { ...location.state, personalDetails },
            });
          }
        });
      } else {
        navigate("/signup-address", {
          state: { ...location.state, personalDetails },
        });
      }
    }
    // if (personalDetails.Type == "shareHolder") {
    //   checkShareHolderExistatnce(personalDetails).then((res) => {
    //     if (res?.data) {
    //       setCount((prev) => {
    //         return prev + 1;
    //       });
    //       if (!validation(personalDetails)) {
    //         navigate("/signup-address", {
    //           state: { ...location.state, personalDetails },
    //         });
    //       }
    //     }
    //   });
    // } else {
    //   setCount((prev) => {
    //     return prev + 1;
    //   });
    //   if (!validation(personalDetails)) {
    //     navigate("/signup-address", {
    //       state: { ...location.state, personalDetails },
    //     });
    //   }
    // }
  };

  useEffect(() => {
    if (location?.state?.personalDetails) {
      // const {
      //   Name,
      //   NationalID,
      //   Mobile,
      //   Email,
      //   Type,
      //   DOB,
      //   Password,
      //   Confirm,
      //   Parent_name,
      //   Parent_NationalID,
      //   Relation,
      //   Contact,
      //   Account_No,
      // } = location?.state?.personalDetails;
      setPersonalDetails(location?.state?.personalDetails);
    }
  }, [location?.state]);

  useEffect(() => {
    getSetUpDateAndTime().then((res) => {
      if (res?.data) {
        const currentDate = new Date(); // Get current date and time
        const isoDateTime = currentDate.toISOString();
        if (res?.data?.result?.timeFrame) {
          if (
            res?.data?.result?.startDateAndTime <= isoDateTime &&
            res?.data?.result?.endDateAndTime > isoDateTime
          ) {
            setShow(true);
          } else {
            setShow(false);
          }
        } else {
          setShow(true);
        }
      }
    });
  }, []);
  console.log("errors", errors);
  return (
    <div>
      <div className="main">
        <div className="logo-box mb-4 mt-0 w-[100%] h-auto flex justify-center items-center bg-[#CEDEFE]">
          <img
            src="/images/mtdc_logo.png"
            alt="image"
            style={{ height: "auto", width: "25%" }}
          />
        </div>
        <h3 className="mainHeading mb-5">Registration</h3>
        <Typography
          style={{
            margin: "0 auto",
            color: "#fff",
            fontSize: "19px",
            textAlign: "left",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                borderRadius: "50%",
                width: "33px",
                height: "33px",
                border: "1px solid white",
                backgroundColor: "#35C203",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  padding: "2px",
                }}
              >
                1
              </span>
            </div>
            <div
              style={{
                border: "1px solid #333333AB",
                width: "300px",
                height: "0",
                alignItems: "center",
                margin: "15px 0",
              }}
            ></div>
            <div
              style={{
                borderRadius: "50%",
                width: "33px",
                height: "33px",
                border: "1px solid black",
                color: "black",

                // backgroundColor: "#35C203",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  padding: "2px",
                }}
              >
                2
              </span>
            </div>
            <div
              style={{
                border: "1px solid #333333AB",
                width: "300px",
                height: "0",
                alignItems: "center",
                margin: "15px 0",
              }}
            ></div>
            <div
              style={{
                borderRadius: "50%",
                width: "33px",
                height: "33px",
                border: "1px solid black",
                // backgroundColor: "#35C203",
                color: "black",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  padding: "2px",
                }}
              >
                3
              </span>
            </div>
            <div
              style={{
                border: "1px solid #333333AB",
                width: "300px",
                height: "0",
                alignItems: "center",
                margin: "15px 0",
              }}
            ></div>
            <div
              style={{
                borderRadius: "50%",
                width: "33px",
                height: "33px",
                border: "1px solid black",
                // backgroundColor: "#35C203",
                color: "black",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "17px",
                  fontWeight: "600",
                  padding: "2px",
                }}
              >
                4
              </span>
            </div>
          </div>
        </Typography>
        <div className="personalInfo">
          <h3>Personal Details</h3>
        </div>
        <div className="infoContainer">
          <div>
            <label htmlFor="">Select Type</label>
            <TextField
              id="outlined-basic"
              select
              name="Type"
              defaultValue={personalDetails?.Type}
              value={personalDetails?.Type}
              onChange={handleChange}
            >
              <MenuItem key={"shareHolder"} value={"shareHolder"}>
                Share Holder
              </MenuItem>
              {show && (
                <MenuItem key={"proxy"} value={"proxy"}>
                  Proxy
                </MenuItem>
              )}
            </TextField>
            <label htmlFor="">Name</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter Your Name"
              name="Name"
              onChange={handleChange}
              value={personalDetails?.Name}
              helperText={
                errors.Name ? (
                  <FormHelperText style={{ color: "red" }}>
                    Name should be atleast 3 characters.
                  </FormHelperText>
                ) : null
              }
            />
            <label htmlFor="">DOB</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={[
                  "DatePicker",
                  "MobileDatePicker",
                  "DesktopDatePicker",
                  "StaticDatePicker",
                ]}
              >
                <DatePicker
                  name="DOB"
                  disableFuture
                  onChange={(dateObject) => {
                    // Assuming dateObject.$d is the Date object
                    const formattedDate = `${dateObject.$D}-${(
                      dateObject.$M + 1
                    )
                      .toString()
                      .padStart(2, "0")}-${dateObject.$y}`;
                    if (new Date().getFullYear() - dateObject?.$y <= 18) {
                      setMinor(true);
                      setPersonalDetails({
                        ...personalDetails,
                        DOB: formattedDate,
                        Minor: true,
                      });
                    } else {
                      setMinor(false);
                      setPersonalDetails({
                        ...personalDetails,
                        DOB: formattedDate,
                        Minor: false,
                      });
                    }
                    if (count > 1) {
                      validation(personalDetails);
                    }
                  }}
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      error: Boolean(errors.DOB),
                      helperText: errors.DOB ? (
                        <FormHelperText style={{ color: "red" }}>
                          Please select a valid date.
                        </FormHelperText>
                      ) : null,
                    },
                  }}
                  // renderInput={(params) => (
                  //   <TextField
                  //     {...params}
                  //     error={Boolean(errors.DOB)} // Set error state
                  //     helperText={
                  //       errors.DOB ? (
                  //         <FormHelperText style={{ color: "red" }}>
                  //           Please select a valid date.
                  //         </FormHelperText>
                  //       ) : null
                  //     }
                  //   />
                  // )}
                />
              </DemoContainer>
            </LocalizationProvider>

            {personalDetails?.Minor && (
              <>
                <label htmlFor="">Name of Guardians</label>
                <TextField
                  id="outlined-select-currency"
                  placeholder="Enter Name Of Guardian"
                  name="Parent_name"
                  onChange={handleChange}
                  value={personalDetails?.Parent_name}
                  helperText={
                    errors.Parent_name ? (
                      <FormHelperText style={{ color: "red" }}>
                        Perent Name is required
                      </FormHelperText>
                    ) : null
                  }
                />
                <label htmlFor="">Relation</label>
                <TextField
                  id="outlined-select-currency"
                  placeholder="Enter Relation with Guardian"
                  name="Relation"
                  onChange={handleChange}
                  value={personalDetails?.Relation}
                  helperText={
                    errors.Relation ? (
                      <FormHelperText style={{ color: "red" }}>
                        Relation info is required
                      </FormHelperText>
                    ) : null
                  }
                />
              </>
            )}
            <label htmlFor="">Email</label>
            <TextField
              id="outlined-select-currency"
              name="Email"
              type="email"
              onChange={handleChange}
              placeholder="Enter Your E-mail"
              value={personalDetails?.Email}
              helperText={
                errors.Email ? (
                  <FormHelperText style={{ color: "red" }}>
                    email is not correct
                  </FormHelperText>
                ) : null
              }
            />
          </div>
          <div>
            <label htmlFor="">National ID</label>
            <TextField
              id="outlined-select-currency"
              name="NationalID"
              placeholder="Enter Your NationalID"
              onChange={handleChange}
              value={personalDetails?.NationalID}
              helperText={
                errors.NationalID ? (
                  <FormHelperText style={{ color: "red" }}>
                    National Id length should be atleast 5 character
                  </FormHelperText>
                ) : null
              }
            />
            <label htmlFor="">Contact No.</label>

            <TextField
              id="outlined-select-currency"
              name="Mobile"
              type="number"
              onChange={handleChange}
              placeholder="Enter Your mobile No."
              value={personalDetails?.Mobile}
              helperText={
                errors.Mobile ? (
                  <FormHelperText style={{ color: "red" }}>
                    Mobile Number should be 10 digits
                  </FormHelperText>
                ) : null
              }
            />
            {personalDetails?.Minor && (
              <>
                <label htmlFor="">Guardian's ID</label>
                <TextField
                  id="outlined-select-currency"
                  placeholder="Enter Your Guardian's National Id"
                  name="Parent_NationalID"
                  onChange={handleChange}
                  value={personalDetails?.Parent_NationalID}
                  helperText={
                    errors.Parent_NationalID ? (
                      <FormHelperText style={{ color: "red" }}>
                        Parent NationalID should be atleast 5 character
                      </FormHelperText>
                    ) : null
                  }
                />
                <label htmlFor="">Guardian's Contact</label>
                <TextField
                  id="outlined-select-currency"
                  placeholder="Enter your Guardian's Mobile No."
                  name="Contact"
                  onChange={handleChange}
                  value={personalDetails?.Contact}
                  // helperText={
                  //   errors.Password ? (
                  //     <FormHelperText style={{ color: "red" }}>
                  //       Password should be atleast 8 characters including alphabets,
                  //       numeric value and special character
                  //     </FormHelperText>
                  //   ) : null
                  // }
                />
              </>
            )}
            {personalDetails?.Type == "shareHolder" && (
              <>
                <label htmlFor="">Account Number</label>
                <TextField
                  id="outlined-select-currency"
                  name="Account_No"
                  type="number"
                  onChange={handleChange}
                  placeholder="Enter Your Account No."
                  value={personalDetails?.Account_No}
                  helperText={
                    errors.Account_No ? (
                      <FormHelperText style={{ color: "red" }}>
                        Account Number is not valid
                      </FormHelperText>
                    ) : null
                  }
                />
              </>
            )}
          </div>
        </div>
        <div className="personalInfo">
          <h3>Create Password</h3>
        </div>
        <div className="infoContainer">
          <div>
            {" "}
            <label htmlFor="">Password</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Enter Your Password"
              name="Password"
              type={showPass.password ? "text" : "password"}
              onChange={handleChange}
              value={personalDetails?.Password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          password: !showPass.password,
                        })
                      }
                      edge="end"
                    >
                      {showPass.password ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                errors.Password ? (
                  <FormHelperText style={{ color: "red" }}>
                    Password should be atleast 8 characters including alphabets,
                    numeric value and special character
                  </FormHelperText>
                ) : null
              }
            />
          </div>
          <div>
            <label htmlFor="">Confirm Password</label>
            <TextField
              id="outlined-select-currency"
              placeholder="Confirm Your Password"
              name="Confirm"
              onChange={handleChange}
              value={personalDetails?.Confirm}
              type={showPass.confirm ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPass({
                          ...showPass,
                          confirm: !showPass.confirm,
                        })
                      }
                      edge="end"
                    >
                      {showPass.confirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={
                errors.Confirm ? (
                  <FormHelperText style={{ color: "red" }}>
                    Password is not same
                  </FormHelperText>
                ) : null
              }
            />
          </div>
        </div>

        <div className="nextBtn flex justify-center my-5">
          <button onClick={handleSubmit} className="btn mx-auto">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
